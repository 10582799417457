<template>
    <div>
        <h4>Vacation applications</h4>
        <p class="breadcrumb">
            <router-link to="schemas">Schemas</router-link> / 
            <router-link to="applyforvacation">Vacation application</router-link> /
            <router-link to="vacationapplications">My applications</router-link>
        </p>

        <router-link to="applyforvacation" 
                     tag="b-button" 
                     class="aip-button" 
                     style="background-color: #0275d8; border: none; width: 200px; margin-bottom: 20px;">
                     
                     Apply for vacation

        </router-link>

        <p v-if="this.applications.length === 0">No applications<p>

        <div class="cards tables" 
             style="padding-bottom: 2px;" 
             v-for="application in applications" 
             :key="application.docId" 
             v-bind:class="[application.status === 'Pending' ? 'pending' : application.status === 'Accepted' ? 'accepted' : application.status === 'Declined' ? 'rejected' : '']">
             <!-- <h5>Application sent: {{ application.timestamp.toDate() | parseTime }}</h5> -->
             <h5>{{ application.status }}</h5>
             <p><span style="width: 100%; padding-left: 0"><b>Application sent:</b><br> {{ application.timestamp.toDate() | parseTime }}</span></p>
             <hr>
             <p>
                <span class="vacation-date" style="display: inline-block; width: 30%; padding-left: 0"><b>From:</b><br> {{ application.vacationFrom | reorderDate }}</span> 
                <span class="vacation-date" style="display: inline-block; width: 30%; padding-left: 0"><b>To:</b><br> {{ application.vacationFrom | reorderDate }} </span> 
                <span class="vacation-status" style="display: inline-block; width: 30%; padding-left: 0"><b>Status:</b><br> {{ application.status }}</span><br><br>
                <span style="display: inline-block; width: 100%" v-if="application.reason != null"><b>Reason for decision:</b><br> {{ application.reason }}</span>
             </p>
        </div>
    </div>
</template>

<script>
    import firebase from 'firebase/app'
    import moment from 'moment'
    export default {
        data() {
            return {
                applications: [],
            }
        },

        methods: {
            getApplications(){
                const db = firebase.firestore()
                const self = this;
                const userInfo = JSON.parse(sessionStorage.getItem("lbUser"))
                db.collection("vacationapplications").where("uid", "==", userInfo.id)
                    .orderBy("timestamp", "desc")
                    .onSnapshot(function(snapshot){
                        snapshot.forEach(function(doc){
                            self.applications.push(doc.data())
                        })
                    })
            },

            findIndexOf(id) {
                return docId == id // eslint-disable-line
            }
        },

        filters: {
            reorderDate(time){
                const year = time.slice(0,4);
                const month = time.slice(5,7)
                const day = time.slice(8,10)
                return `${day}/${month}/${year}`
            },

            parseTime(time) {
                return moment(time).format("Do/MM/YYYY, HH:mm ")
            },
        },

        created(){
            this.getApplications()
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';

@media only screen and (max-width: 992px) {
    .tables {
        .vacation-date {
            width: 49% !important;
        }

        .vacation-status {
            width: 100%;
        }
    }
}
</style>