<template>
    <div>
        <h4>Payment in advance application</h4>
        <p class="breadcrumb"><router-link to="schemas"> Schemas </router-link> / <router-link to="paymentinadvance"> Payment in advance application </router-link></p>
        <!-- <div class="cards" v-if="this.loaded"> -->
        <router-link to="Paymentinadvanceapplications" tag="b-button" class="aip-button" style="background-color: #0275d8; border: none; margin-bottom: 20px;">My applications</router-link><br>
        <p class="infoMessage accepted" v-if="formSent">Form sent. You will get an answer on your application within five days.</p>
        <h5 class="" v-if="this.appliedForAdvance && !formSent">You already have an application under consideration. You will get an answer within five days from the day you applied. </h5>
        <h5 class="" v-if="this.userInfo.advancePaymentTimes == 3">You have reached your limit of payment in advance for this year.</h5>

        <div v-if="this.loaded && !appliedForAdvance">
            <div class="cards" v-if="this.loaded && userInfo.advancePaymentTimes != 3">
                <p><b>You can get paid in advance three times during a year.</b></p>
                <!-- <p><b>
                    First time: Max 10 000 NOK<br>
                    Second time: Max 8000 NOK<br>
                    Third time: Max 5000 NOK
                </b></p> -->
                <p><b>You have been paid in advance {{ userInfo.advancePaymentTimes }} / 3 times.</b></p>

                
                <div class="tables">
                    <b-form v-on:submit.prevent="applyForPaymentInAdvance($event)">
                        <!-- <b-form-group
                            id="input-group-1"
                            label="Amount: "
                            label-for="amount"
                            style="font-size: 12px; font-weight: 600"

                        >
                            <b-form-input
                                id="amount"
                                type="text"
                                placeholder="The amount you wish to apply for"
                                v-model="amount"
                                required
                            ></b-form-input>
                        </b-form-group> -->
                        <b-form-group label="Choose amount:" v-slot="{ ariaDescribedby }" style="font-size: 12px; font-weight: 600; margin-bottom: 0;">
                            <b-form-radio-group
                                id="checkbox-group-2"
                                v-model="amount"
                                :aria-describedby="ariaDescribedby"
                                name="who-was-sick"
                                style="margin-bottom: 15px;"
                            >
                                <b-form-radio value="5000"  style="font-size: 16px; font-weight: 400;">5000 NOK</b-form-radio>
                                <b-form-radio value="4000" style="font-size: 16px; font-weight: 400;">4000 NOK</b-form-radio>
                                <b-form-radio value="3000" style="font-size: 16px; font-weight: 400;">3000 NOK</b-form-radio>
                                <b-form-radio value="2000" style="font-size: 16px; font-weight: 400;">2000 NOK</b-form-radio>
                                <b-form-radio value="1000" style="font-size: 16px; font-weight: 400;">1000 NOK</b-form-radio>
                                <b-form-radio value="Other" style="font-size: 16px; font-weight: 400;">Other</b-form-radio>
                            </b-form-radio-group>

                            <b-form-group
                                id="input-group-1"
                                label="Your amount in NOK: "
                                label-for="description"
                                style="font-size: 12px; font-weight: 600"
                                v-if="amount === 'Other'"
                            >
                                <b-form-input
                                    id="Other amount"
                                    type="text"
                                    placeholder="Write in your desired amount"
                                    v-model="otherAmount"
                                ></b-form-input>
                            </b-form-group>
                        </b-form-group>

                        <b-form-group
                            id="input-group-1"
                            label="Description: "
                            label-for="description"
                            style="font-size: 12px; font-weight: 600"

                        >
                            <b-form-input
                                id="description"
                                type="text"
                                placeholder="The reason you apply"
                                v-model="description"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            id="input-group-1"
                            label="Account number: "
                            label-for="account"
                            style="font-size: 12px; font-weight: 600"

                        >
                            <b-form-input
                                id="account"
                                type="text"
                                placeholder="Your bank account number"
                                v-model="accountNumber"
                                required
                            ></b-form-input>
                        </b-form-group>

                        <div class="text-right">
                            <b-button type="submit" variant="primary" id="add-employee-btn" class="aip-button">Send form</b-button>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import firebase from 'firebase/app'// eslint-disable-line
    import moment from 'moment'
    export default {
        data() {
            return {
                userInfo: null,
                loaded: false,
                formSent: false,
                date: "",
                amount: "",
                otherAmount: "",
                description: "",
                accountNumber: "",
                appliedForAdvance: false,
            }
        },

        methods: {
            async applyForPaymentInAdvance(e) {
                const db = firebase.firestore();
                const newDoc = db.collection("paymentinadvance").doc()
                const newDocRef = await newDoc.get()
                const userId = this.userInfo.id;

                try {
                    await db.collection("paymentinadvance").doc(newDocRef.id).set({
                        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                        docId: newDocRef.id,
                        uid: userId,
                        datePosted: this.date,
                        status: "Pending",
                        name: this.userInfo.fullName,
                        amount: this.amount,
                        otherAmount: this.otherAmount,
                        description: this.description,
                        accountNumber: this.accountNumber,
                        reason: null
                    })

                    db.collection("users").doc(this.userInfo.id)
                    .update({
                        appliedForAdvance: true,
                        // advancePaymentTimes: this.userInfo.advancePaymentTimes += 1
                    })
                    .then(() => {
                        let sessionUserInfo = JSON.parse(sessionStorage.getItem("lbUser"))
                        sessionUserInfo.appliedForAdvance = true;
                        sessionUserInfo.advancePaymentTimes += 1;
                        sessionStorage.setItem("lbUser", JSON.stringify(sessionUserInfo));
                        // this.userInfo.appliedForPayment = true;
                        console.log("document updated");
                    })
                    .catch((error) => {
                        console.log("Error updating document: ", error);
                    })

                    this.formSent = true
                    this.appliedForAdvance = true
                    e.target.reset()
                    console.log("Sucess")
                } catch (e) {
                    console.log("Error", e)
                }
            }
        },

        beforeCreate() {
            // TODO Change all this.$store to session storage!!
            const getUserInfo = async () => JSON.parse(sessionStorage.getItem("lbUser"));
            getUserInfo().then(res => {
                this.userInfo = res
                this.loaded = true
                this.appliedForAdvance = res.appliedForAdvance
                console.log(this.userInfo, 123123123)
            })

        },

        created() {
            const d = new Date();
            const parsedD = moment(d).format('Do/MM/YYYY')
            this.date = parsedD;
        }
        
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';
</style>