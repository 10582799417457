 <template>
    <div>
        <!-- <div class="[ latest-notes-mob ]  [ d-block d-lg-none ]">
            <b-container>
            <p style="margin: 0; font-size: 12px; font-weight: bold">Notifications: </p>

                <p style="font-size: 14px;" class="[ latest-notes-mob-notes ]" v-if="latestNotesMobTitle == ''">Loading...</p>
                <p style="font-size: 14px;" class="[ latest-notes-mob-notes ]" v-if="latestNotesMobTitle != ''"><span style="font-size: 12px; margin-right: 5px;">{{ latestNotesMobTime | parseTime  }}</span> <router-link to="notifications">{{ latestNotesMobTitle }}</router-link></p>

            </b-container>
        </div> -->
        <b-row>
            <b-col md="3" lg="2" id="sidenav" class="d-none d-md-block">
                <h4>Navigation</h4><br>
                
                <h6>General</h6>
                <router-link to="/employee/home"><b-icon icon="chevron-right" class="mobile-navigation-icon"></b-icon> Information</router-link><br>
                <router-link to="/employee/vaktplan"><b-icon icon="chevron-right" class="mobile-navigation-icon"></b-icon> Vaktplan</router-link><br><br>
                <!-- <a target="_blank" href="https://torweb.net/elwi/vaktplan/vaktplan.pdf"><b-icon icon="chevron-right" class="mobile-navigation-icon"></b-icon> Vaktplan</a><br><br> -->

                <h6>Schemas</h6>
                <router-link to="/employee/applyforvacation"><b-icon icon="chevron-right" class="mobile-navigation-icon"></b-icon> Vacation application</router-link><br>
                <router-link to="/employee/paymentinadvance"><b-icon icon="chevron-right" class="mobile-navigation-icon"></b-icon> Payment in advance</router-link><br>
                <router-link to="/employee/selfcertification"><b-icon icon="chevron-right" class="mobile-navigation-icon"></b-icon> Self-certification of illness</router-link><br>
                <router-link to="/employee/other"><b-icon icon="chevron-right" class="mobile-navigation-icon"></b-icon> Other</router-link><br><br>

                <!-- <h6>Settings</h6>
                <a href="#" v-b-modal.modal-1>Change password</a> -->

                <!-- <h6>General</h6>
                <router-link to="/employee/notifications">Contact</router-link><br><br> -->

                <h6 style="margin-top:20px;">User information</h6>
                <p style="font-size: 14px; margin: 0"><b>Name: </b><br>{{ userLoggedInName }}</p>
                <p style="font-size: 14px;"><b>Company: </b><br>{{ userLoggedInCompany }}</p>
                
                
                <b-button type="submit" variant="primary" v-on:click="logout" class="aip-button" style="margin-top: 20px;">Log out</b-button>
            </b-col>

            <b-col md="8" id="dashboard-content">
                <router-view></router-view>
            </b-col>

            <!-- <b-col md="2" id="latest-notes" class="d-none d-lg-block">
                <h4>Useful links</h4>
                <ul style="padding: 0; font-size: 16px; list-style-type: none">
                <li style="margin-bottom: 10px;">
                    <a href="">
                </li>
                </ul>
            </b-col> -->



            <b-col id="mobile-navigation" class="[ mobile-navigation d-block d-md-none ]">
                <ul>
                    <li>
                        <router-link to="/employee/home"><b-icon icon="house-door-fill" class="mobile-navigation-icon"></b-icon><br>Home</router-link>
                    </li>
                    <li>
                        <router-link to="/employee/vaktplan"><b-icon icon="calendar2-range-fill" class="mobile-navigation-icon"></b-icon><br>Vaktplan</router-link>
                        <!-- <a target="_blank" href="https://torweb.net/elwi/vaktplan/vaktplan.pdf"><b-icon icon="calendar2-range-fill" class="mobile-navigation-icon"></b-icon><br>Vaktplan</a> -->
                    </li>
                    <li>
                        <router-link to="/employee/schemas"><b-icon icon="calendar-fill" class="mobile-navigation-icon"></b-icon><br>Schemas</router-link>
                    </li>
                    <li>
                        <a href="#" v-on:click.prevent="logout"><b-icon icon="door-open-fill" class="mobile-navigation-icon"></b-icon><br>Log out</a>
                    </li>
                </ul>
            </b-col>
        </b-row>

        <b-modal ok-only ok-title="Cancel" ok-variant="" id="modal-1" title="Change password">
            <p class="my-4">By clicking the button below you will receive an email with a link to change your password. <br><br>If you don't find the email in your inbox, check your spam folder.</p>
            <b-button variant="primary" v-b-modal.modal-1 @click="changePassword" class="aip-button">Send mail</b-button>
        </b-modal>

       
    </div>     
</template>

<script>
    import firebase from 'firebase';
    import moment from 'moment'

    export default {
        data() {
            return {
                userLoggedInName: "",
                userLoggedInCompany: "",
                messages: [],
            }
        },

        methods: {
            async getNotifications() {
                const self = this;
                const db = firebase.firestore()
                await db.collection("messages")
                    .orderBy("timestamp", "desc").limit(5)
                    .onSnapshot(function(snapshot){
                        self.messages = [];
                        snapshot.forEach(function(snapshot){
                            self.messages.push(snapshot.data())
                        })
                    })
            },

            logout(){
                firebase.auth().signOut()
                    .then(() => {
                        sessionStorage.clear()
                        this.$router.push("/")
                    }).catch((error) => {
                        console.log(error);
                    });

            },

            async changePassword(){
                const auth = await firebase.auth()
                const user = await auth.currentUser.email
                
                try {
                    const changePass = await auth.sendPasswordResetEmail(user) // eslint-disable-line
                    return {
                        result: "Please check your email for instructions on changing your password"
                    }
                } catch (error){
                    return {
                        result: "Something went wrong: ", error
                    }
                }
                
            }
        },

        filters: {
            reorderDate(time){
                const year = time.slice(0,4);
                const month = time.slice(5,7)
                const day = time.slice(8,10)
                return `${day}/${month}/${year}`
            },

            parseTime(time) {
                return moment(time).format("Do/MM/YYYY, HH:mm ")
            },
        },

        beforeCreate(){
            const self = this

            const checkUser = async function() {
                const userToken = await JSON.parse(sessionStorage.getItem("lbUserTk"));
                const userInfo = await JSON.parse(sessionStorage.getItem("lbUser"));

                if(userToken && userToken.claims.employee) {
                    console.log("User claims ok!")
                } else {
                    self.$router.push("/")
                    return
                }
                
                self.userLoggedInName = userInfo.fullName
                self.userLoggedInCompany = userInfo.company
            }
            
            checkUser()
        },

        created() {
            this.getNotifications();
        },

        mounted() {

        },

        beforeDestroy() {

        }
    }
</script>

<style lang="scss" scoped>
@import '../scss/shared';
    #sidenav {
        .space {
            padding-top: 20px 0 !important;
        }
    }

    #dashboard-content {
        padding-left: 50px;

        #message {
            margin-bottom: 20px;
            // background-color: rgb(223, 230, 255);
            background-color: rgb(251, 252, 255);
            border: none !important;
            box-shadow: 0px 1px 5px rgb(216, 216, 216);
        }
    }

    #latest-notes {
        padding-left: 40px;

        h4 {
            margin-bottom: 30px;
        }
    }

    .mobile-navigation {
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: #fafafa;
        border-top: 1px solid #e9e9e9;
        text-align: center;
        padding-top: 10px;

        ul {
            padding: 0;

            li {
                font-size: 14px;
                display: inline-block;
                padding: 0 15px;

                a {
                    color: #2c3e50;
                }

                .mobile-navigation-icon {
                    font-size: 20px;
                    margin-bottom: -5px;
                }
            }
        }
    }   

    @media only screen and (max-width: 768px) {
        #dashboard-content {
            padding-left: 0;
            padding: 0 30px 50px;
        }

        #latest-notes{
            padding-left: 30px;
        }
    }
</style>