import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import moment from 'moment'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(moment);

Vue.config.productionTip = false

let app = '';

// ********************************** //
// ********** DEVELOPMENT *********** //
// ********************************** //
// const firebaseConfig = {
//   apiKey: "AIzaSyAyjMw_2rwJXJ7Zn2fVcbWObkLue1VZUto",
//   authDomain: "elwi-hr-management-dev.firebaseapp.com",
//   projectId: "elwi-hr-management-dev",
//   storageBucket: "elwi-hr-management-dev.appspot.com",
//   messagingSenderId: "603081246823",
//   appId: "1:603081246823:web:2eeb8393370dc0f19becc2"
// };
// ********************************** //
// ********** DEVELOPMENT *********** //
// ********************************** //

// ********************************** //
// *********** PRODUCTION *********** //
// ********************************** //
const firebaseConfig = {
  apiKey: "AIzaSyDByKl1cW7YKpS6-uN9MsPldpu7z2_FT24",
  authDomain: "elwi-hr-management.firebaseapp.com",
  projectId: "elwi-hr-management",
  storageBucket: "elwi-hr-management.appspot.com",
  messagingSenderId: "496319736269",
  appId: "1:496319736269:web:f3269ae4706c27cb0a7c84"
};
// ********************************** //
// *********** PRODUCTION *********** //
// ********************************** //

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

// firebase.getCurrentUser = () => {
//   return new Promise((resolve, reject) => {
//       const unsubscribe = firebase.auth().onAuthStateChanged(user => {
//           unsubscribe();
//           resolve(user);
//       }, reject);
//   })
// };


// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')


firebase.auth().onAuthStateChanged(() => {
  if(!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})



