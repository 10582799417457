<template>
    <div>
        <h4>Vacation application</h4>

        <p class="breadcrumb">
            <router-link to="schemas"> Schemas </router-link> / 
            <router-link to="applyforvacation"> Vacation application </router-link>
        </p>

        <router-link to="Vacationapplications" tag="b-button" class="aip-button" style="background-color: #0275d8; border: none;">My applications</router-link><br>
        <p v-if="this.loaded && this.userInfo.appliedForVacation" class="cards pending"><b-icon icon="calendar"></b-icon> You currently have an application under consideration. See <router-link to="/employee/vacationapplications">applications</router-link>.</p>

        <div v-if="this.loaded && !this.userInfo.appliedForVacation">
            <b-container id="calendar-container">
                <b-row id="calendar-row" class="cards">
                    <b-col lg="6" class="cards-calendar cards-calendar-from">
                        <div class="text-left">
                            <h5>From: </h5>
                        </div>
                        <b-calendar block v-model="vacationFrom" locale="en-DK" :min="minDate"></b-calendar>
                    </b-col>
                    
                    <b-col lg="6" class="cards-calendar cards-calendar-to">
                        <div class="text-left">
                            <h5>To: </h5>
                        </div>
                        <b-calendar block v-model="vacationTo" locale="en-DK" :min="minDate"></b-calendar>
                    </b-col>

                    <b-col lg="12" style="padding: 0;">
                        <div class="text-left additional-information">
                            <label>Additional Information (Optional):</label><br>
                            <textarea v-model="additionalInformation"></textarea>
                        </div>
                    </b-col>

                    <b-col lg="12" class="text-right" style="padding: 0; margin-top: 20px;">
                        <b-button type="submit" variant="primary" id="login-button" class="aip-button" v-on:click="applyForVacation">Apply</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
    import firebase from 'firebase/app' 
    import 'firebase/auth'
    import moment from 'moment'

    export default {
        data() {
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            const minDate = new Date(today)
            return {
                loaded: false,
                userInfo: null,
                minDate: minDate,
                vacationFrom: "",
                vacationTo: "",
                dateApplied: "",
                additionalInformation: "",
                applicationSent: false,
                applications: [],
            }
        },
        methods: {
            async applyForVacation() {
                const db = firebase.firestore()
                const newDoc = db.collection('vacationapplications').doc()
                const newDocRef = await newDoc.get()
                console.log(newDocRef.id)

                await db.collection('vacationapplications').doc(newDocRef.id).set({
                    docId: newDocRef.id,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    uid: this.userInfo.id,
                    name: this.userInfo.fullName,
                    company: this.userInfo.company,
                    dateApplied: this.dateApplied,
                    vacationFrom: this.vacationFrom,
                    vacationTo: this.vacationTo,
                    additionalInformation: this.additionalInformation,
                    status: "Pending",
                    reason: null
                })

                db.collection("users").doc(this.userInfo.id)
                .update({
                    appliedForVacation: true
                })
                .then(() => {
                    let sessionUserInfo = JSON.parse(sessionStorage.getItem("lbUser"))
                    sessionUserInfo.appliedForVacation = true;
                    sessionStorage.setItem("lbUser", JSON.stringify(sessionUserInfo));
                    this.userInfo.appliedForVacation = true;
                    console.log("document updated");
                })
                .catch((error) => {
                    console.log("Error updating document: ", error);
                })
            },

            listenForChange(){
                const db = firebase.firestore()
                const self = this

                db.collection("users").doc(this.userInfo.id)
                    .onSnapshot(function(doc) {
                        if (doc.data().appliedForVacation == false){
                            self.userInfo.appliedForVacation = false
                        }
                    })
            }
        },

        beforeCreate() {
            // TODO Change all this.$store to session storage!!
            const getUserInfo = async () => JSON.parse(sessionStorage.getItem("lbUser"));
            getUserInfo().then(res => {
                this.userInfo = res
                this.loaded = true
                console.log(this.userInfo, 123123123)
            })

        },

        created() {
            const d = new Date();
            const parsedD = moment(d).format('Do/MM/YYYY')
            this.dateApplied = parsedD;
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';

    #calendar-container {
        padding: 20px 0 50px 0;
        text-align: center;
    }

    .additional-information {
        margin-top: 20px;
        textarea{
            width: 100%;
            resize: none;
            border-radius: 5px;
            border: 1px solid rgb(192, 192, 192);
            padding: 10px;
        } 
    }

    .pending {
        background-color: rgb(255, 255, 145);
        margin-top: 20px;
    }

    .notPending {
        background-color: blue;
    }
</style>