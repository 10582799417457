<template>
    <div>
        <h4>Home</h4>

        <p class="breadcrumb"><router-link to="home">General</router-link> / <router-link to="home">Home</router-link></p>
    

        <b-row>
            <b-col lg="6" class="left-info">
                <div class="cards tables" style="min-height: 320px; background-color: rgb(255, 255, 143)">
                    <h5>Elwira</h5>
                    <p style="margin-bottom: 0">
                        <span style="padding: 0"><b>Working hours: </b><br>08:00 - 16:00</span><span style="padding: 0"><b>Phone: </b><br>97699302</span>
                    </p>
                    <p style="margin-bottom: 0">
                        
                    </p>
                    <p>
                        <b>Email: </b><br>elwira@aipgruppen.no
                    </p>

                    <p style="margin-bottom: 0;">
                        <b>Status :</b><br>
                        <span style="width: 100%; padding: 10px; color: #0079FF;" v-if="statusMessage.length != 0">{{ statusMessage[0].message }}</span>
                    </p>
                </div>
            </b-col>

            <b-col lg="6" class="right-info">
                <div class="cards tables" style="min-height: 320px; background-color: rgb(255, 255, 143)">
                    <h5>Latest posts</h5>
                    <p v-if="regularPosts.length == 0">No posts found!</p>
                    <p v-for="post in regularPosts.slice(0,3)" v-bind:key="post.id" ><span style="width: 100%; padding: 0;"><b>{{ post.date.toDate() | parseTime }}</b><br> <a :href="'/employee/home#'+post.id">{{post.title}}</a></span></p>
                </div>
            </b-col>

            <!-- <b-col lg="12" style="margin: 0; padding: 0;">
                <div class="cards" style="min-height: 300px; overflow-x: scroll; overflow: ellipse">
                    <h5>Vaktplan</h5>
                    <p style="font-weight: bold;">To look at the vaktplan in a seperate tab <a target="_blank" href="https://torweb.net/elwi/vaktplan/vaktplan.pdf">click here</a>.</p>
                    <iframe src="https://torweb.net/elwi/vaktplan/vaktplan.pdf" height="500" width="100%"></iframe><br><br>
                </div>
            </b-col> -->

            <b-col lg="12" style="margin: 0; padding: 0;">
                <div class="cards" style="min-height: 300px; overflow-x: scroll; overflow: ellipse">
                    <h5>Important dates</h5>
                    <b-table bordered striped small :items="tableItems" style="font-size: 14px;"></b-table>
                    <div class="text-center d-block d-md-none animate__animated animate__pulse animate__infinite" style="margin-bottom: 20px;">
                        <b-icon icon="arrow-left"></b-icon><span style="font-weight: 800;"> Swipe to see </span><b-icon icon="arrow-right"></b-icon>
                    </div>
                </div>
            </b-col>

            <p v-if="this.regularPosts.length === 0">No posts found</p>

            <b-col lg="12" v-for="post in regularPosts" :key="post.id" :data-id="post.id" :id="post.id" class="cards">
                <p style="font-size: 14px;">{{ post.date.toDate() | parseTime }}</p>
                <h5>{{ post.title }}</h5>
                
                <hr>
                
                <p>{{ post.message }}</p>
                <p>{{ post.author }}</p>

            </b-col>

        </b-row>
    </div>
</template>

<script>
    import firebase from 'firebase';
    import ImportantDatesClass from '../../shared/class'
    import moment from 'moment'

    export default {
        data() {
            return {
                statusMessage: [],
                regularPosts: [],
                tableItems: [
                    { 'month / week': 'Week 8', tripletex_will_be_locked: '28.02.2022', salary: '' },
                    { 'month / week': 'February 2022', tripletex_will_be_locked: '01.03.2022', salary: '25.03.2022', _rowVariant: 'danger' },
                    { 'month / week': 'Week 9', tripletex_will_be_locked: '07.03.2022', salary: '' },
                    { 'month / week': 'Week 10', tripletex_will_be_locked: '14.03.2022', salary: '' },
                    { 'month / week': 'Week 11', tripletex_will_be_locked: '21.03.2022', salary: '' },
                    { 'month / week': 'Week 12', tripletex_will_be_locked: '28.03.2022', salary: '' },
                    { 'month / week': 'Week 13', tripletex_will_be_locked: '04.05.2022', salary: '' },
                    { 'month / week': 'March 2022', tripletex_will_be_locked: '01.03.2022', salary: '25.04.2022', _rowVariant: 'danger' },
                ],
            }
        },

        methods: {
            async getPersonalStatusMessage(){
                const db = firebase.firestore()

                try{
                    const getPersonalStatusMessages = await db.collection("posts").doc("personalStatus").get()
                    const personalStatusMessages = await Object.values(getPersonalStatusMessages.data()) // eslint-disable-line

                    for(let obj of personalStatusMessages) {// eslint-disable-line
                        this.statusMessage.push(obj)
                    }
                    
                    this.statusMessage.sort(function(a,b) {
                        return b.date - a.date
                    })

                } catch (error) {
                    this.statusMessage.push({message: 'Error loading status message: ' + error ,})
                    console.log(error)
                }   
            },

            async getRegularPosts() {
                const db = firebase.firestore()

                try {
                    const getRegularPosts = await db.collection("posts").doc("regularPost").get()
                    const regularPosts = await Object.values(getRegularPosts.data()) // eslint-disable-line

                    for(let obj of regularPosts){
                        this.regularPosts.push(obj)
                    }

                    this.regularPosts.sort(function(a,b) {
                        return b.date - a.date
                    })

                } catch (error) {
                    console.log(error)
                }

            },

            // async getImportantDates() {
            //     const db = firebase.firestore()

            //     try {
            //         const getImportantDates = await db.collection("posts").doc("importantDates").get()
            //         const importantDates = await Object.values(getImportantDates.data())
                    
            //         for(let obj of importantDates){
            //             let importantDateObj = new ImportantDatesClass(
            //                 obj.month.charAt(0).toUpperCase() + obj.month.slice(1), 
            //                 obj.last_day_to_register_hours,
            //                 obj.tripletex_will_be_locked,
            //                 obj.accept_hours_by_leaders,
            //                 obj.salary_payout,
            //                 obj._cellVariants
            //             )

            //             this.tableItems.push(importantDateObj)
            //         }

            //         const monthsByNumber = {
            //             'January': 1, 
            //             'February' : 2, 
            //             'March': 3,
            //             'April': 4,
            //             'May': 5,
            //             'June': 6,
            //             'July': 7,
            //             'August': 8,
            //             'September': 9,
            //             'October': 10,
            //             'November': 11,
            //             'December': 12
            //             }
                    
            //         this.tableItems.sort(function(a,b) {
            //             return monthsByNumber[a.month] - monthsByNumber[b.month]
            //         })  
            //     } catch (error) {
            //         console.log(error)
            //     }                     
            // }
        },

        filters: {
            reorderDate(time){
                const year = time.slice(0,4);
                const month = time.slice(5,7)
                const day = time.slice(8,10)
                return `${day}/${month}/${year}`
            },

            parseTime(time) {
                return moment(time).format("Do/MM/YYYY, HH:mm ")
            },
        },

        created() {
            this.getPersonalStatusMessage()
            this.getImportantDates();
            this.getRegularPosts()
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';
@import '../../../node_modules/animate.css/animate.min.css';
    $card-color: #ffffff;
    .cards {
        background-color: $card-color;
        padding: 20px;
        box-shadow: 0px 2px 5px rgb(173, 173, 173);
        border-radius: 5px;
        margin-bottom: 20px;
    }

    .left-info {
        padding-left: 0;
        padding-right: 10px;
    }

    .right-info {
        padding-right: 0;
        padding-left: 10px;
    }

@media only screen and (max-width: 992px) {
    .left-info {
        padding-right: 0;
    }

    .right-info {
        padding-left: 0;
    }
}
</style>