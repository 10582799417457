<template>
    <div>
        <h4>Payment in advance applications</h4>
        <p class="breadcrumb">
            <router-link to="adminschemas"> Schemas </router-link> / 
            <router-link to="advancedpaymentapplications"> Payment in advance applications </router-link> /
            <router-link to="processedadvancedpayment"> Processed payment in advance applications </router-link>
        </p>

        <div style="margin-bottom: 20px;">
            <router-link to="advancedpaymentapplications">
                        
                        Pending applications

            </router-link>
        </div>

        <p v-if="applications.length === 0">No schemes</p>

        <div>
            <b-row>
                <b-col lg="12" class="cards tables" v-for="application in applications" :key="application.docId">
                    <h5>{{ application.status }}</h5>
                    <hr>
                    <p><span><b>Date: </b><br>{{ application.datePosted.toString().replace('th','') }}</span></p>
                    <p><span><b>Name:</b><br>{{application.name}}</span> <span v-if="application.otherAmount == ''"><b>Amount:</b><br>{{ application.amount }} NOK</span> <span v-if="application.otherAmount != ''"><b>Other amount:</b><br>{{ application.otherAmount }} NOK</span></p>
                    <p><span><b>Description:</b><br>{{application.description}}</span> <span><b>Account number:</b><br>{{application.accountNumber}}</span></p>
                    <p><span><b>Signed by:</b><br>{{application.signedBy}}</span></p>
                    <!-- <p v-if="scheme.whoWasSick === 'Child sickness'"><span><b>Name of child: </b><br>{{ scheme.nameOfChild }}</span></p> -->
                    <!-- <p><span><b>From date:</b><br>{{scheme.sickFromDate | reorderDate }}</span> <span><b>To date:</b><br>{{ scheme.sickToDate | reorderDate }}</span></p> -->
                    <!-- <p><span><b>Number of days: </b><br>{{ scheme.numberOfDays }}</span></p> -->
                    <!-- <p><span><b>Comment: </b><br>{{ scheme.comment }}</span></p> -->
                    <!-- <p><span><b>Signed by: </b><br>{{ scheme.signedBy }}</span></p> -->

                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import firebase from 'firebase/app'
    export default {
        data() {
            return {
                applications: []
            }
        },

        methods: {
            async getSchemes(){
                const db = firebase.firestore()
                const collection = await db.collection("paymentinadvance").orderBy('timestamp', 'desc').get()
                const docs = await collection.forEach(doc => { // eslint-disable-line
                    if(doc.data().status == "Accepted" || doc.data().status == "Declined") {
                        this.applications.push(doc.data())
                        console.log(doc.data())
                    }
                })
            }
        },

        filters: {
            reorderDate(time){
                const year = time.slice(0,4);
                const month = time.slice(5,7)
                const day = time.slice(8,10)
                return `${day}/${month}/${year}`
            }
        },

        created(){
            this.getSchemes()
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';
</style>