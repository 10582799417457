<template>
    <div>
        <h4>Notifications</h4>

        <p class="breadcrumb"><router-link to="home">Home</router-link> / <router-link to="notifications">Notifications</router-link></p>
        
        <b-row>
            <b-col lg="6" class="left-info">
                <div class="cards" style="min-height: 300px; background-color: rgb(255, 255, 143)">
                    <h5>Welcome</h5>
                    <p>Here you can apply for vacation, payment in advance and send in your self certification
                        of illness scheme.
                    </p>
                </div>
            </b-col>

            <b-col lg="6" class="right-info">
                <div class="cards" style="min-height: 300px; background-color: rgb(255, 255, 143)">
                    <h5>Elwira working hours</h5>
                    <p>
                        08:00 - 16:00
                    </p>
                    <p>
                        elwira@aipgruppen.no
                    </p>
                    <p>
                        97699302
                    </p>
                </div>
            </b-col>
            <b-col lg="12">
                <div class="cards" style="min-height: 300px; background-color: rgb(255, 255, 143)">
                    <h5>Important dates of february</h5>

                    <p>
                        31.01.2021 - its the last day to fill out Tripletex ( hours, utlegg, diett and kilometers)
                    </p>
                    <p>
                        01.02.2021 - Tripletex will be locked at 09:00 
                    </p>
                    <p>
                        05.02.2021 - Leaders finish checking Hours, utlegg, diett, kilometers
                    </p>
                    <p>
                        25.02.2021 - Salary January '21
                    </p>
                </div>
            </b-col>

            <p v-if="this.messages.length === 0">No notifications</p>

            <b-col lg="12" v-for="message in messages" :key="message.id" :data-id="message.id" class="cards">
                <p style="font-size: 14px;">{{ message.timePosted }}</p>
                <h5>{{ message.title }}</h5>
                
                <hr>
                
                <p>{{ message.message }}</p>
                <p>{{ message.author }}</p>
            </b-col>

        </b-row>
    </div>
</template>

<script>
    import firebase from 'firebase';

    export default {
        data() {
            return {
                messages: []
            }
        },

        methods: {
            getNotifications() {
                const self = this;
                const db = firebase.firestore()
                db.collection("messages")
                    .orderBy("timestamp", "desc")
                    .onSnapshot(function(snapshot){
                        self.messages = [];
                        snapshot.forEach(function(snapshot){
                            self.messages.push(snapshot.data())
                        })
                    }) 
            }
        },

        created() {
            
            this.getNotifications();
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';
    $card-color: #ffffff;
    .cards {
        background-color: $card-color;
        padding: 20px;
        box-shadow: 0px 2px 5px rgb(173, 173, 173);
        border-radius: 5px;
        margin-bottom: 20px;
    }

    .left-info {
        padding-left: 0;
        padding-right: 10px;
    }

    .right-info {
        padding-right: 0;
        padding-left: 10px;
    }

@media only screen and (max-width: 992px) {
    .left-info {
        padding-right: 0;
    }

    .right-info {
        padding-left: 0;
    }
}
</style>