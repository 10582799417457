 <template>
    <div>
        <!-- <div class="[ latest-notes-mob ]  [ d-block d-lg-none ]">
            <b-container>
            <p style="margin: 0; font-size: 12px; font-weight: bold">Notifications: </p>

                <p style="font-size: 14px;" class="[ latest-notes-mob-notes ]" v-if="latestNotesMobTitle == ''">Loading...</p>
                <p style="font-size: 14px;" class="[ latest-notes-mob-notes ]" v-if="latestNotesMobTitle != ''"><span style="font-size: 12px; margin-right: 5px;">{{ latestNotesMobTime | parseTime  }}</span> <router-link to="notifications">{{ latestNotesMobTitle }}</router-link></p>

            </b-container>
        </div> -->
        <b-row>
            <b-col md="3" lg="2" id="sidenav" class="d-none d-md-block">
                <h4>Navigation</h4><br>

                <h6>General</h6>
                <router-link to="/dashboard/adminhome">Home</router-link><br>
                <div v-if="userRole === 'admin'">
                    <router-link to="/dashboard/addpost">Add post</router-link><br>
                    <!-- <router-link to="/dashboard/notifications">Manage notifications</router-link><br> -->
                </div><br>

                <h6>Schemas</h6>
                <router-link to="/dashboard/employeevacationapplications">Vacation</router-link><br>
                <router-link to="/dashboard/advancedpaymentapplications">Payment in advance</router-link><br>
                <router-link to="/dashboard/scoi">Self-certification of illness</router-link><br>
                <router-link to="/dashboard/contactschemas">Other</router-link><br><br>

                <div v-if="userRole === 'admin'">
                    <h6>Users</h6>
                    <router-link to="/dashboard/users">Add user</router-link><br>
                    <router-link to="/dashboard/manageusers">Manage users</router-link><br><br>
                </div>

                <h6 style="font-size: 14px;">Logged in as:</h6>
                <p style="font-size: 14px; margin: 0 0 20px 0">{{ userName }}</p>
                <b-button type="submit" variant="primary" v-on:click="logout" class="aip-button">Log out</b-button>
            </b-col>

            <b-col md="8" id="dashboard-content">
                <router-view></router-view>
            </b-col>

            <!-- <b-col md="2" id="latest-notes" class="d-none d-lg-block" style="padding-left: 40px;">
                <h4>Latest notes</h4>
                <ul style="padding: 0; font-size: 16px; list-style-type: none">
                <li v-for="message in messages" :key="message.id" style="margin-bottom: 10px;">
                    <span style="font-size: 12px;">{{ message.timestamp.toDate() | parseTime  }}</span>
                    <router-link to="/employee/notifications" >{{ message.title }}</router-link>
                </li>
                </ul>
            </b-col> -->

            <b-col id="mobile-navigation" class="[ mobile-navigation d-block d-md-none ]" style="z-index: 100">
                <ul>
                    <li>
                        <router-link to="/dashboard/adminhome"><b-icon icon="house-fill" class="mobile-navigation-icon"></b-icon><br>Home</router-link>
                    </li>
                        <li>
                            <router-link to="/dashboard/adminschemas"><b-icon icon="calendar-fill" class="mobile-navigation-icon"></b-icon><br>Schemas</router-link>
                        </li>
                        <li v-if="userRole === 'admin'">
                            <router-link to="/dashboard/users"><b-icon icon="person-lines-fill" class="mobile-navigation-icon"></b-icon><br>Users</router-link>
                        </li>
                    <li>
                        <a href="#" v-on:click.prevent="logout"><b-icon icon="door-open-fill" class="mobile-navigation-icon"></b-icon><br>Log out</a>
                    </li>
                </ul>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import firebase from 'firebase';
    import 'firebase/auth'
    import moment from 'moment'

    export default {
        data() {
            return {
                messages: [],
                userName: "",
                userRole: ""
            }
        },

        methods: {
            async getNotifications() {
                const self = this;
                const db = firebase.firestore()
                await db.collection("messages")
                    .orderBy("timestamp", "desc").limit(5)
                    .onSnapshot(function(snapshot){
                        self.messages = [];
                        snapshot.forEach(function(snapshot){
                            self.messages.push(snapshot.data())
                        })
                    })
            },

            logout(){
                firebase.auth().signOut().then(() => {
                    sessionStorage.clear()
                    this.$router.push("/")
                }).catch((error) => {
                    console.log(error);
                });
            },
        },

        filters: {
            reorderDate(time){
                const year = time.slice(0,4);
                const month = time.slice(5,7)
                const day = time.slice(8,10)
                return `${day}/${month}/${year}`
            },

            parseTime(time) {
                return moment(time).format("Do/MM/YYYY, HH:mm ")
            },
        },

        beforeCreate() {
            const self = this
            const checkUser = async function(){

                const userToken = await JSON.parse(sessionStorage.getItem("lbUserTk"))
                const userInfo = await JSON.parse(sessionStorage.getItem("lbUser"))
                
                if(userToken && userToken.claims.admin || userToken && userToken.claims.teamleader){
                    if(userToken.claims.admin) {
                        self.userRole = 'admin';
                    } else if(userToken.claims.teamleader){
                        self.userRole = 'teamleader'
                    }
                } else {
                    // self.$router.push("/")
                    console.log("Not allowed")
                }

                // REMEMBER TO UNCOMMENT AFTER CHECK
                self.userName = userInfo.fullName
            }

            checkUser();
        },

        created() {
            this.getNotifications();
        }
    }
</script>

<style lang="scss" scoped>
@import '../scss/shared';
    #dashboard-content {
        padding-left: 50px;

        #message-status {
            color: green;
        }
    }

    .aip-button {
        width: 150px;
    }

    .logout-icon {
        margin-right: 10px;
        font-size: 16px !important;
    }

    .mobile-navigation {
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: #fafafa;
        border-top: 1px solid #e9e9e9;
        text-align: center;
        padding-top: 10px;

        ul {
            padding: 0;

            li {
                font-size: 14px;
                display: inline-block;
                padding: 0 15px;

                a {
                    color: #2c3e50;
                }

                .mobile-navigation-icon {
                    font-size: 20px;
                    margin-bottom: -5px;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        #dashboard-content {
            padding-left: 0;
            padding: 0 30px 50px;
        }
    }
</style>