<template>
    <div>
        <h4>Self Certification of Illness</h4>
        <p class="breadcrumb">
            <router-link to="adminschemas"> Schemas </router-link> / 
            <router-link to="scoi"> Self Certification of Illness </router-link>
        </p>

        <div style="margin-bottom:20px">
            <router-link to="processedscoi">
                        Processed schemas
            </router-link>
        </div>

        <p v-if="scoischemas.length === 0">No new schemas</p>

        <div>
            <b-row>
                <b-col lg="12" class="cards tables" v-for="scheme in scoischemas" :key="scheme.docId" :data-id="scheme.docId">
                    <h4 style="padding-left: 10px;">{{ scheme.typeOfScoi }}</h4>
                    <p><span><b>Time received: </b><br>{{ scheme.datePosted.toString() }}</span></p>
                    <p><span><b>Name:</b><br>{{scheme.name}}</span> <span><b>Type:</b><br>{{ scheme.typeOfScoi }}</span></p>
                    <p><span v-if="scheme.typeOfSykemelding != ''"><b>Type of sykemelding:</b><br>{{ scheme.typeOfSykemelding }}</span></p>
                    <p><span v-if="scheme.whoWasSick != ''"><b>Type of absence:</b><br>{{ scheme.whoWasSick }}</span></p>
                    <p v-if="scheme.whoWasSick === 'Child sickness'"><span><b>Name of child: </b><br>{{ scheme.nameOfChild }}</span> <span><b>Birthdate of child: </b><br>{{ scheme.dateOfBirthChild | reorderDate }} UAUAU</span></p>
                    <p><span><b>Date:</b><br>{{ scheme.sickFromDate | reorderDate }}</span> <span v-if="scheme.typeOfScoi === 'Sykemelding'"><b>To date:</b><br>{{ scheme.sickToDate | reorderDate }}</span></p>
                    <p><span><b>Number of days: </b><br>{{ scheme.numberOfDays }} days</span></p>
                    <hr>
                    <p style="margin-bottom: 0"><span><b>Comment:</b><br></span></p>
                    <div style="padding: 0 10px">
                        <b-textarea v-model="comment"></b-textarea>
                    
                        <div class="text-right" style="margin-top: 20px;">
                            <b-button variant="success" type="submit" @click="updateScoi($event, 'Accepted')" class="aip-button" style="margin-right: 10px;">Accept</b-button>
                            <b-button variant="danger" type="submit" @click="updateScoi($event, 'Rejected')" class="aip-button">Decline</b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import firebase from 'firebase/app'
    export default {
        data() {
            return {
                userLoggedIn: "",
                scoischemas: [],
                comment: "",
            }
        },

        methods: {
            async getSchemes(){
                this.scoischemas = []
                const self = this;
                const db = firebase.firestore()
                const collection = await db.collection('selfcertificationofillness').get()
                const documents = await collection.forEach(doc => { // eslint-disable-line
                    if(doc.data().scoiStatus == "Pending"){
                        self.scoischemas.push(doc.data())
                    }
                })
            },

            async updateScoi(e, status){// eslint-disable-line
                const self = this
                const db = firebase.firestore()
                const accDec = status// eslint-disable-line
                const comment = this.comment
                const docId = e.target.parentElement.parentElement.parentElement.getAttribute('data-id');// eslint-disable-line
                const docRef = await db.collection('selfcertificationofillness').doc(docId)
                const updateDoc = await docRef.update({ // eslint-disable-line
                    scoiStatus: accDec,
                    comment: comment,
                    signedBy: this.userLoggedIn
                })


                self.getSchemes()
            },
        },

        filters: {
            reorderDate(time){
                const year = time.slice(0,4);
                const month = time.slice(5,7)
                const day = time.slice(8,10)
                return `${day}/${month}/${year}`
            }
        },

        created(){
            const userInfo = JSON.parse(sessionStorage.getItem("lbUser"))
            this.userLoggedIn = userInfo.fullName;
            this.getSchemes()
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';
</style>