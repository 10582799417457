import Vue from 'vue'
import Vuex from 'vuex'
import { createStore } from 'vuex-extensions'

Vue.use(Vuex)

export default createStore(Vuex.Store, {
  state: {
    isMobile: null,
    userIs: null,
    userId: null,
    userFullName: null,
    userEmail: null,
    userCompany: null,
    appliedForVacation: false,
    appliedForAdvance: false,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})

// export default new Vuex.Store({
//   state: {
//     isMobile: null,
//     userIs: null,
//     userId: null,
//     userFullName: null,
//     userEmail: null,
//     userCompany: null,
//     appliedForVacation: false,
//     appliedForAdvance: false,
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
