<template>
    <div>
        <h4>Schemas</h4>
        <p class="breadcrumb"><router-link to="adminschemas">Schemas</router-link></p>

        <router-link to="applyforvacation">Vacation application</router-link><br>
        <router-link to="paymentinadvance">Payment in advance application</router-link><br>
        <router-link to="selfcertification">Self certification of illness</router-link><br>
        <router-link to="other">Other</router-link>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';
</style>