<template>
    <div>
        <h4>Notifications</h4>
        <!-- <div id="mobile-messages-navigation" class="d-block d-md-none"> -->
            <!-- <router-link to="/dashboard/addmessages">Inbox</router-link><br> -->
            <!-- <router-link to="/dashboard/addnotification">New notification</router-link><br>
            <router-link to="/dashboard/notifications">Manage notifications</router-link><br><br>
        </div> -->

        <p class="breadcrumb">
            <router-link to="adminhome"> General </router-link> / 
            <router-link to="addpost"> Add post </router-link>
        </p>

        <!-- IF NOTE ADDED -->
        <p v-if="infoMessage != ''" class="infoMessage" v-bind:class="[succErr === 'success' ? 'success' : succErr === 'error' ? 'error' : '' ]"><b>{{ infoMessage }}</b></p>
        <!-- <p v-if="reqMessage != ''" class="infoMessage" v-bind:class="[succErr === 'success' ? 'success' : succErr === 'error' ? 'error' : '' ]">{{ reqMessage }}</p> -->
        
        <b-overlay :show="loading" rounded="sm">

            <div class="cards">
                <b-form style="font-size: 12px; font-weight: 600">
                    <b-form-group
                        id="posttype"
                        label="Post type: "
                        label-for="posttype-select"
                        >
                        
                        <b-form-select 
                            id="posttype-select"
                            v-model="postType"
                            required
                            >
                            
                            <b-form-select-option 
                                value="null"
                                > Choose post type
                            </b-form-select-option>
                            <b-form-select-option 
                                value="regularPost"
                                > Regular post
                            </b-form-select-option>
                            <b-form-select-option 
                                value="personalStatus"
                                > Personal status
                            </b-form-select-option>
                            <b-form-select-option 
                                value="importantDates"
                                > Important dates
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                    <!-- ************* -->
                    <!-- REGULAR NOTE -->
                    <!-- ************* -->
                    <div v-if="postType === 'regularPost'">
                        <b-form-group
                        id="input-group-1"
                        label="Title:"
                        label-for="title"
                    >
                        <b-form-input
                            id="title"
                            type="text"
                            v-model="regularPost.title"
                            required
                        ></b-form-input>
                        </b-form-group>
                        
                        <b-form-group
                            id="input-group-1"
                            label="Notification:"
                            label-for="message"
                        >
                            <b-form-textarea
                                id="message"
                                type="text"
                                v-model="regularPost.message"
                                required
                            ></b-form-textarea>
                        </b-form-group>
                    </div>
                    <!-- ************* -->
                    <!-- REGULAR NOTE -->
                    <!-- ************* -->

                    <!-- ************* -->
                    <!-- PERSONAL NOTE -->
                    <!-- ************* -->
                    <div v-if="postType === 'personalStatus'">
                        <div v-if="postType === 'personalStatus'" class="cards tables" style="max-width: 350px; min-height: 300px; background-color: rgb(255, 255, 143);  margin: 0 auto; margin-bottom: 20px;">
                            <h5>Elwira</h5>
                            <p style="margin-bottom: 0">
                                <span style="padding: 0; font-size: 16px; font-weight: 400;"><b>Working from: </b><br>08:00 - 16:00</span><span style="padding: 0; font-size: 16px; font-weight: 400;"><b>Email: </b><br>elwira@aipgruppen.no</span>
                            </p>
                            <p style="margin-bottom: 0">
                                
                            </p>
                            <p>
                                <b>Phone: </b><br>
                                <span style="padding: 0; font-size: 16px; font-weight: 400;">97699302</span>
                            </p>

                            <p style="margin-bottom: 0;">
                                <b>Status:</b><br>
                                <span style="width: 100%; padding: 0; font-size: 16px; font-weight: 400;">{{ personalStatus.message }}</span>
                            </p>
                        </div>
                        
                        <b-form-group
                            id="personalstatus"
                            v-if="postType === 'personalStatus'"
                            label="Note text: "
                            label-for="personalstatus-text"
                            >
                            <b-form-textarea 
                                id="personalstatus-text"
                                v-model="personalStatus.message" 
                                maxlength="160"
                                placeholder="Your note here (Max 160 characters)"
                                >
                            </b-form-textarea>
                            <p v-if="personalStatus" style="margin-top: 10px; font-weight: 400;">Characters left: {{ 160 - personalStatus.message.length }}</p>
                        </b-form-group>
                    </div>
                    <!-- ************* -->
                    <!-- PERSONAL NOTE -->
                    <!-- ************* -->

                    <!-- *************** -->
                    <!-- IMPORTANT DATES -->
                    <!-- *************** -->
                    <div v-if="postType === 'importantDates'">
                        <div class="cards"  style="background-color: #ffffff; padding: 10px; margin: 20px 0; overflow-x: scroll">
                            <p style="margin-bottom: 10px;">Preview</p>
                            <b-table bordered small :items="importantDates"></b-table>
                        </div>

                        <b-form-group
                        id="importantdates-month-group"
                        label="Month: "
                        label-for="importantdates-month-select"
                        >
                        
                        <b-form-select 
                            id="importantdates-month-select"
                            v-model="importantDates[0].month"
                            required
                            >
                            
                            <b-form-select-option 
                                value="Choose month"
                                > Choose month
                            </b-form-select-option>
                            <b-form-select-option 
                                value="January"
                                > January
                            </b-form-select-option>
                            <b-form-select-option 
                                value="February"
                                > February
                            </b-form-select-option>
                            <b-form-select-option 
                                value="March"
                                > March
                            </b-form-select-option>
                            <b-form-select-option 
                                value="April"
                                > April
                            </b-form-select-option>
                            <b-form-select-option 
                                value="May"
                                > May
                            </b-form-select-option>
                            <b-form-select-option 
                                value="June"
                                > June
                            </b-form-select-option>
                            <b-form-select-option 
                                value="July"
                                > July
                            </b-form-select-option>
                            <b-form-select-option 
                                value="August"
                                > August
                            </b-form-select-option>
                            <b-form-select-option 
                                value="September"
                                > September
                            </b-form-select-option>
                            <b-form-select-option 
                                value="October"
                                > October
                            </b-form-select-option>
                            <b-form-select-option 
                                value="November"
                                > November
                            </b-form-select-option>
                            <b-form-select-option 
                                value="December"
                                > December
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                        <b-form-group
                            id="importantdates-last_day_to_register_hours-group"
                            label="Last day to register hours: "
                            label-for="importantdates-last_day_to_register_hours-input"
                        >   
                            <b-form-input
                                id="importantdates-last_day_to_register_hours-input"
                                type="text"
                                v-model="importantDates[0].last_day_to_register_hours"> 
                            >
                            </b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="importantdates-tripletex_will_be_locked-group"
                            label="Tripletex will be locked: "
                            label-for="importantdates-tripletex_will_be_locked-input"
                        >
                            <b-form-input
                                id="importantdates-tripletex_will_be_locked-input"
                                type="text"
                                v-model="importantDates[0].tripletex_will_be_locked"> 
                            >
                            </b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="importantdates-accept_hours_by_leaders-group"
                            label="Accept hours by leaders: "
                            label-for="importantdates-accept_hours_by_leaders-input"
                        >
                            <b-form-input
                                id="importantdates-accept_hours_by_leaders-input"
                                type="text"
                                v-model="importantDates[0].accept_hours_by_leaders"> 
                            >
                            </b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="importantdates-salary_payout-group"
                            label="Salary payout: "
                            label-for="importantdates-salary_payout-input"
                        >
                            <b-form-input
                                id="importantdates-salary_payout-input"
                                type="text"
                                v-model="importantDates[0].salary_payout"> 
                            >
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <!-- *************** -->
                    <!-- IMPORTANT DATES -->
                    <!-- *************** -->

                    <div class="text-right" v-if="postType">
                        <b-button type="submit" variant="primary" id="addmessage-button" class="aip-button" @click.prevent="addPost($event)">Post</b-button>
                    </div>

                </b-form>
                
                <!-- <p>{{ post }}</p> -->
                
            </div>
        </b-overlay>

    </div>
</template>

<script>
    import firebase from 'firebase';
    import moment from 'moment'

    export default {
        props: ['userRoleFromParent'],
        data() {
            return {
                userName: "",
                loading: false,
                postAdded: false,
                succErr: "",
                infoMessage: "",
                postType: null,
                regularPost: {
                    id: "",
                    date: new Date(),
                    author: this.userName,
                    title: "",
                    message: ""
                },
                importantDates: [{
                    month: 'Choose month', 
                    last_day_to_register_hours: '', 
                    tripletex_will_be_locked: '', 
                    accept_hours_by_leaders: '', 
                    salary_payout: '', 
                    _cellVariants: { accept_hours_by_leaders: 'info'}
                }],
                personalStatus: {
                    date: new Date(),
                    message: "",
                },
            }
        },

        methods: {
            generateId() {
                const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

                let result = ' ';
                const charactersLength = characters.length;
                for ( let i = 0; i < 16; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }

                return result.slice(1,17);
            },

            async addPost(e){
                this.loading = true
                const postType = this.postType
                const db = await firebase.firestore()
                const postForm = e.target.parentElement.parentElement
                console.log(postForm)
                
                
                try {
                    const colRef = await db.collection("posts")
                    if(postType === "importantDates"){
                        let month = this.importantDates[0].month.toLowerCase();
                        const setDoc = await colRef.doc(this.postType).update({
                            [month] : this.importantDates[0]
                        })
                    } else if (postType === "regularPost") {
                        let docId = this.generateId();
                        this.regularPost.id = docId
                        const setDoc = await colRef.doc(this.postType).update({
                            [docId] : this.regularPost 
                        })
                    } else if (postType === "personalStatus") {
                        let docId = this.generateId()
                        const setDoc = await colRef.doc(this.postType).update({
                            [docId] : this.personalStatus
                        })
                    }
                
                    postForm.reset()
                    this.loading = false
                    this.succErr = "success"
                    this.infoMessage = "Post added!"
                    
                    return
                } catch (error) {
                    console.log(error)
                    this.loading = false
                    this.succErr = "error"
                    this.infoMessage = "Something went wrong: " + error
                    
                    return
                }
            }
        },

        filters: {
            reorderDate(time){
                const year = time.slice(0,4);
                const month = time.slice(5,7)
                const day = time.slice(8,10)
                return `${day}/${month}/${year}`
            },

            parseTime(time) {
                return moment(time).format("Do / MMM / YYYY")
            },
        },

        beforeCreate() {
            const userToken = JSON.parse(sessionStorage.getItem("lbUserTk"))
            const userInfo = JSON.parse(sessionStorage.getItem("lbUser"));
            
            if(!userToken.claims.admin) {
                this.$router.push("/dashboard/adminhome")
            }

            this.userName = userInfo.fullName;
        },
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';
    // $card-color: #ffffff;
    #sidenav {
        border-right: 1px solid rgb(201, 200, 200);

        ul {
            list-style-type: none;
            padding-left: 0;
        }
    }

    #dashboard-content {
        padding-left: 50px;

        #message-status {
            color: green;
        }
    }

    // .cards {
    //     background-color: $card-color;
    //     padding: 20px;
    //     box-shadow: 0px 2px 5px rgb(173, 173, 173);
    //     border-radius: 5px;
    // }
</style>