<template>
    <div>
        <h4>Other</h4>
        <p class="breadcrumb"><router-link to="schemas"> Schemas </router-link> / <router-link to="other"> Other </router-link></p>

        <p class="infoMessage accepted" v-if="formSent">Form sent</p>

        <div class="cards">
           <b-form v-on:submit.prevent="submitForm($event)">
              <b-form-group id="input-group-3" label="What is the case about?:" label-for="input-3">
                <b-form-select
                  id="input-3"
                  :options="options"
                  v-model="form.option"
                  required
                ></b-form-select>
              </b-form-group>

              <!-- CHANGE OF PERSONAL INFORMATION SUCH AS ADDRESS -->
              <div v-if="form.option === 'change'">

                <p>Please write all information you want to be changed.</p>
                <b-form-textarea
                  id="textarea"
                  v-model="form.details"
                  placeholder="Write your details here"
                  rows="6"
                  max-rows="6"
                  required
                ></b-form-textarea>
              </div>

              <!-- RESIGNATION -->
              <div v-if="form.option === 'resignation'">
                <h5>I hereby resign my position at {{ userInfo.company }}.</h5><br>
                <b-form-group
                  id="input-group-4"
                  label="My last working date will be: "
                  label-for="lastWorkingDate"
                  style="display: inline-block; font-size: 12px; font-weight: 600; width: 49%"
                >
                  <b-form-input
                    id="lastWorkingDate"
                    type="date"
                    placeholder=""
                    v-model="form.resignation.lastWorkingDate"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="I would like to get vacation money with my last salary:" v-slot="{ ariaDescribedby }" style="font-size: 12px; font-weight: 600">
                  <b-form-radio-group
                    id="checkbox-group-2"
                    v-model="form.resignation.vacationMoney"
                    :aria-describedby="ariaDescribedby"
                    name="vacationMoney"
                    required
                  >
                    <b-form-radio value="Yes, only for last year" style="font-size: 16px; font-weight: 400;">Yes, only for last year</b-form-radio>
                    <b-form-radio value="For both last year and this year" style="font-size: 16px; font-weight: 400;">Yes, for both last year and this year (Vacation money from this year will be taxed)</b-form-radio>
                    <b-form-radio value="No" style="font-size: 16px; font-weight: 400;">No</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="I have delivered all items belonging to AIP (Any items not delivered will be charged from vacation payment):" v-slot="{ ariaDescribedby }" style="font-size: 12px; font-weight: 600">
                  <b-form-radio-group
                    id="checkbox-group-2"
                    v-model="form.resignation.deliveredItems"
                    :aria-describedby="ariaDescribedby"
                    name="deliveredItems"
                    required
                  >
                    <b-form-radio value="Yes" style="font-size: 16px; font-weight: 400;">Yes</b-form-radio>
                    <b-form-radio value="No" style="font-size: 16px; font-weight: 400;">No</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
                <p style="font-weight: bold; font-size: 12px; margin-bottom: 10px;">Comment:</p>
                <b-form-textarea
                  id="textarea"
                  v-model="form.resignation.details"
                  placeholder="Additional comment"
                  rows="4"
                  max-rows="4"
                  required
                ></b-form-textarea><br>
                <p><span style="font-size: 12px;"><b>Signed by:</b></span><br> {{ userInfo.fullName }}</p>
              </div>

              <!-- SALARY -->
              <div v-if="form.option === 'salary'">
                <p>If your salary is wrong please write all details which can help me to fix it. It will be corrected and pay out with next salary.</p>
                <b-form-textarea
                  id="textarea"
                  v-model="form.details"
                  placeholder="Write your details here"
                  rows="6"
                  max-rows="6"
                  required
                ></b-form-textarea>
              </div>

              <!-- OTHER CONCERNS -->
              <div v-if="form.option === 'other'">
                <p>Please describe your case.</p>
                <b-form-textarea
                  id="textarea"
                  v-model="form.details"
                  placeholder="Write your details here"
                  rows="6"
                  max-rows="6"
                  required
                ></b-form-textarea>
              </div>
              <br>
              <div v-if="form.option != null" class="text-right">
                <b-button type="submit" variant="primary">Send</b-button>
                <!-- <b-button type="reset" variant="danger">Reset</b-button> -->
              </div>
          </b-form>
        </div>
       

    </div>
</template>

<script>
import firebase from 'firebase/app'// eslint-disable-line
import moment from 'moment'
    export default {
      data() {
        return {
          userInfo: null,
          resignation: false,
          form: {
            date: null,
            option: null,
            details: null,
            resignation: {
              lastWorkingDate: null,
              vacationMoney: null,
              deliveredItems: null,
              details: null
            },
          },
          formSent: false,
          options: [
            { text: 'Select one', value: null }, 
            { text: 'Change of address, email, telephone etc', value: 'change'}, 
            { text: 'Resignation', value: 'resignation'},
            { text: 'Salary', value: 'salary'},
            { text: 'Other cases', value: 'other'}
          ],
        }
      },

      methods: {
        datePosted() {
          const d = new Date();
          const parsedD = moment(d).format('Do/MM/YYYY, h:mm a')
          this.form.date = parsedD;
        },

        async submitForm(e){
          console.log(this.form.option);
          this.datePosted()
          const db = firebase.firestore();
          const newDoc = db.collection("other").doc()
          const newDocRef = await newDoc.get()
          const userId = this.userInfo.id;

          this.form.option === 'resignation' ? this.resignation = true : false;

          try {
            db.collection("other").doc(newDocRef.id).set({
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              datePosted: this.form.date,
              docId: newDocRef.id,
              name: this.userInfo.fullName,
              uid: userId,
              typeOfForm: this.form.option,
              details: this.form.details,
              resignation: this.resignation ? this.form.resignation : null,
              treated: false,
              commentFromLeader: null,
              treadedBy: null
          })

          this.form.details = null;
          this.resignation = false;
          this.formSent = true;
          e.target.reset()

          } catch(error) {
            console.log('Error: ', error)
          }
        }
      },

      beforeCreate() {
            // TODO Change all this.$store to session storage!!
            const getUserInfo = async () => JSON.parse(sessionStorage.getItem("lbUser"));
            getUserInfo().then(res => {
                this.userInfo = res
                this.loaded = true
                // console.log(this.userInfo, 123123123)
            })

        },
        
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';
</style>