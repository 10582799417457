<template>
    <div>
        <h4>Users</h4>
        <!-- MOBILE VIEW -->
        <div id="mobile-messages-navigation" class="d-block d-md-none">
            <!-- <router-link to="/dashboard/addmessages">Inbox</router-link><br> -->
            <router-link to="/dashboard/users">Add user</router-link><br>
            <router-link to="/dashboard/manageusers">Manage users</router-link><br><br>
        </div>

        <!-- BREADCRUMB -->
        <p class="breadcrumb">Users / Add user</p>

        <!-- IF USER ADDED -->
        <p v-if="reqMessage != ''" class="infoMessage" v-bind:class="[succErr === 'success' ? 'success' : succErr === 'error' ? 'error' : '' ]" >{{ reqMessage }}</p>

        <b-overlay :show="loading" rounded="sm">
            <div class="cards" id="user-form">
                <b-form v-on:submit.prevent="addEmployee($event)">
                    <b-form-group label="User role:" v-slot="{ ariaDescribedby }" style="font-size: 12px; font-weight: 600">
                        <b-form-radio-group
                            id="checkbox-group-1"
                            v-model="userRole"
                            :aria-describedby="ariaDescribedby"
                            name="user-role"
                            required
                        >
                            <b-form-radio value="admin"  style="font-size: 16px; font-weight: 400;">Admin</b-form-radio>
                            <b-form-radio value="teamleader"  style="font-size: 16px; font-weight: 400;">Team Leader</b-form-radio>
                            <b-form-radio value="employee" style="font-size: 16px; font-weight: 400;">Employee</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>

                    <b-form-group
                        id="input-group-1"
                        label="Company:"
                        label-for="company"
                        style="font-size: 12px; font-weight: 600"
                    >
                        <b-form-select
                            id="company"
                            type="select"
                            :options="[{ text: 'Choose...', value: null }, 'ALTiPROSJEKT AS', 'AiP BETONGSAGING AS', 'AiP ENTREPRENØR AS', 'AIP SANERING AS', 'AIP UTLEIE AS']"
                            v-model="company"
                            required
                        ></b-form-select>
                    </b-form-group>

                    <b-form-group
                        id="input-group-2"
                        label="E-nr: "
                        label-for="enr"
                        style="font-size: 12px; font-weight: 600"
                    >
                        <b-form-input
                            id="enr"
                            type="text"
                            placeholder="Employee number"
                            v-model="enr"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-group-2"
                        label="Full name: "
                        label-for="fullName"
                        style="font-size: 12px; font-weight: 600"
                    >
                        <b-form-input
                            id="fullName"
                            type="text"
                            placeholder="Full name"
                            v-model="fullName"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-group-4"
                        label="Email:"
                        label-for="email"
                        style="font-size: 12px; font-weight: 600"
                    >
                        <b-form-input
                            id="email"
                            type="email"
                            placeholder="Email"
                            v-model="email"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-group-5"
                        label="Password:"
                        label-for="password"
                        style="font-size: 12px; font-weight: 600"
                    >
                        <b-form-input
                            id="password"
                            type="text"
                            placeholder="Password"
                            v-model="password"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        v-if="userRole == 'employee'"
                        id="input-group-5"
                        label="Number of times employee has applied for payment in advance this year:"
                        label-for="password"
                        style="font-size: 12px; font-weight: 600"
                    >
                        <b-form-input
                            id="password"
                            type="text"
                            placeholder="0"
                            v-model="advancePaymentTimes"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group style="z-index: 0;">
                       <b-form-checkbox v-model="sendEmailOnNewUser" value="true">Send login details to email.</b-form-checkbox>
                       <p style="color: red" v-if="sendEmailOnNewUser">Make sure that the email you have entered is correct</p>
                    </b-form-group>
                    
                    <div class="text-right">
                        <b-button type="submit" variant="primary" id="add-employee-btn" class="aip-button">Add user</b-button>
                    </div>

                    
                </b-form>
            </div>
        </b-overlay>
    </div>
</template>

<script>
    import firebase from 'firebase/app'
    import 'firebase/auth'
    import 'firebase/functions'

    export default {
        data() {
            return {
                userRole: "",
                company: "",
                enr: "",
                fullName: "",
                email: "",
                password: "",
                advancePaymentTimes: 0,
                reqMessage: "",
                succErr: "",
                sendEmailOnNewUser: false,
                loading: false
            }
        },
        methods: {
            async addEmployee(e) {
                    const auth = firebase.auth()
                    const db = firebase.firestore()
                    this.loading = true;
                    this.userAdded = false;
                    
                    try {
                        const addUser = await auth.createUserWithEmailAndPassword(this.email, this.password)
                        const addUserToDb = await db.collection("users").doc(addUser.user.uid).set({ // eslint-disable-line
                            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                            id: addUser.user.uid,
                            userRole: this.userRole,
                            company: this.company,
                            enr: this.enr,
                            fullName: this.fullName,
                            email: this.email,
                            appliedForVacation: false,
                            appliedForAdvance: false,
                            advancePaymentTimes: this.advancePaymentTimes
                        })
                        const setUserCustomClaims = await this.addCustomClaims(); // eslint-disable-line

                        if(this.sendEmailOnNewUser){
                            const sendNewUserEmail = await this.sendMail(); // eslint-disable-line
                            this.sendEmailOnNewUser = false;
                            console.log("Email sent")
                        } else {
                            console.log("Email not sent")
                        }
                        
                        e.target.reset();
                        this.loading = false
                        this.succErr = "success"
                        this.reqMessage = `${this.fullName} added as an ${this.userRole}`
                        console.log("Success")
                        return {
                            result: `Success! ${this.email} added as an ${this.userRole}.`
                        }

                    } catch (err) {
                            e.target.reset();
                            this.loading = false
                            this.succErr = "error"
                            this.reqMessage = `Something went wrong. Check that user dont already exists, or try again.`
                            console.log(err)
                            return {
                                result: `Something went wrong: `, err
                            }
                    }

                    
            },

            addCustomClaims() {
                let myData = {
                    email: this.email,
                    role: this.userRole
                }
                const addUserRole = firebase.functions().httpsCallable('addUserRole');
                addUserRole(myData).then(res => {
                    console.log(res)
                }).catch(err => {
                    console.log(err)
                })
            },

            sendMail() {
                let myData = {
                    email: this.email,
                    pass: this.password
                }

                const fbSendMail = firebase.functions().httpsCallable('sendEmail')
                fbSendMail(myData).then(res => {
                    console.log(res)
                }).catch(err => {
                    console.log(err)
                })
            }
        },

        

        beforeCreate() {
            const userToken = JSON.parse(sessionStorage.getItem("lbUserTk"))

            if(!userToken.claims.admin) {
                this.$router.push("/dashboard/adminhome")
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../scss/shared';

</style>