<template>
    <div>
        <h4>Self Certification of Illness</h4>
        <p class="breadcrumb">
            <router-link to="adminschemas"> Schemas </router-link> / 
            <router-link to="scoi"> Self Certification of Illness </router-link> /
            <router-link to="processedscoi">Processed Self Certification of Illness </router-link>
        </p>

        <div style="margin-bottom: 20px;">
            <router-link to="scoi">
                        
                        Pending schemas

            </router-link>
        </div>

        <p v-if="scoischemes.length === 0">No schemes</p>

        <div>
            <b-row>
                <b-col lg="12" class="cards tables" v-for="scheme in scoischemes" :key="scheme.docId">
                    <h5>{{ scheme.scoiStatus }}</h5>
                    <hr>
                    <p><span><b>Date: </b><br>{{ scheme.datePosted.toString().replace('th','') }}</span></p>
                    <p><span><b>Name:</b><br>{{scheme.name}}</span> <span><b>Type of absence:</b><br>{{ scheme.whoWasSick }}</span></p>
                    <p v-if="scheme.whoWasSick === 'Child sickness'"><span><b>Name of child: </b><br>{{ scheme.nameOfChild }}</span></p>
                    <p><span><b>From date:</b><br>{{scheme.sickFromDate | reorderDate }}</span> <span><b>To date:</b><br>{{ scheme.sickToDate | reorderDate }}</span></p>
                    <p><span><b>Number of days: </b><br>{{ scheme.numberOfDays }}</span></p>
                    <p><span><b>Comment: </b><br>{{ scheme.comment }}</span></p>
                    <p><span><b>Signed by: </b><br>{{ scheme.signedBy }}</span></p>

                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import firebase from 'firebase/app'
    export default {
        data() {
            return {
                scoischemes: []
            }
        },

        methods: {
            async getSchemes(){
                const db = firebase.firestore()
                const collection = await db.collection("selfcertificationofillness").orderBy('timestamp', 'desc').get()
                const docs = await collection.forEach(doc => { // eslint-disable-line
                    if(doc.data().scoiStatus == "Accepted" || doc.data().scoiStatus == "Rejected") {
                        this.scoischemes.push(doc.data())
                        console.log(doc.data())
                    }
                })
            }
        },

        filters: {
            reorderDate(time){
                const year = time.slice(0,4);
                const month = time.slice(5,7)
                const day = time.slice(8,10)
                return `${day}/${month}/${year}`
            }
        },

        created(){
            this.getSchemes()
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';
</style>