<template>
    <b-row>

        <b-col lg="8" offset-lg="2">
            <div v-if="!userFound">
                <h4>Password reset</h4>
                <p>Please enter your email address</p>
                <b-input type="text" v-model="email" placeholder="Enter you email"></b-input>
                <div class="text-center">
                    <b-button v-b-modal.modal-1 type="submit" variant="primary" class="aip-button" style="margin: 20px 0;">Reset password</b-button><br>
                    <router-link to="/" style="font-size: 14px; margin-top: 20px;">Back to login</router-link>
                </div>
            </div>

            <div v-if="userFound">
                <h4>Check your inbox</h4>
                <p>A mail with a link to reset your password has been sent your email.</p>
                <p>If you dont find the email please check your spamfolder.</p>
                <router-link to="/" style="font-size: 14px; margin-top: 20px;">Back to login</router-link>
            </div>
        </b-col>

    </b-row>
</template>

<script>
    // import firebase from 'firebase/app'
    // import 'firebase/auth'

    export default {
        data() {
            return {
                email: "",
                userFound: false,
            }
        },

        methods: {

        }
    }
</script>

<style lang="scss" scoped>
$card-color: #ffffff;
@import '../scss/shared';

.infoMessage {
    color: red;
    border: 1px solid red;
    text-align: center;
}
</style>