import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'

//Views
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Employee from '../views/Employee.vue'
import Forgotpassword from '../views/Forgotpassword.vue'

//Components

//Components -> Admin
import Adminhome from '../components/admin/Adminhome.vue'
import Users from '../components/admin/Users.vue'
import Manageusers from '../components/admin/Manageusers.vue'
import Employeevacationapplications from '../components/admin/Employeevacationapplications.vue'
import Processedvacationapplications from '../components/admin/Processedvacationapplications.vue'
import Addpost from '../components/admin/Addpost.vue'
import Notifications from '../components/admin/Notifications.vue'
import Scoi from '../components/admin/Scoi.vue'
import Advancedpaymentapplications from '../components/admin/Advancedpaymentapplications.vue'
import Processedadvancedpayment from '../components/admin/Processedadvancedpayment.vue'
import Processedscoi from '../components/admin/Processedscoi.vue'
import Adminschemas from '../components/admin/Adminschemas.vue'
import Contactschemas from '../components/admin/Contactschemas.vue'
import Processedcontactschemas from '../components/admin/Processedcontactschemas.vue'

//Components -> Employee
import Home from '../components/user/Home.vue'
import Employeenotifications from '../components/user/Employeenotifications.vue'
import Schemas from '../components/user/Schemas.vue'
import Applyforvacation from '../components/user/Applyforvacation.vue'
import Vacationapplications from '../components/user/Vacationapplications.vue'
import Paymentinadvance from '../components/user/Paymentinadvance.vue'
import Paymentinadvanceapplications from '../components/user/Paymentinadvanceapplications.vue'
import Selfcertification from '../components/user/Selfcertification.vue'
import Contact from '../components/user/Contact.vue'
import Vaktplan from '../components/user/Vaktplan.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      { path: '/dashboard/adminhome', name: 'Home', component: Adminhome },
      { path: '/dashboard/users', name: 'Users', component: Users },
      { path: '/dashboard/manageusers', name: 'Manage users', component: Manageusers },
      { path: '/dashboard/employeevacationapplications', name: 'Employee vacation applications', component: Employeevacationapplications },
      { path: '/dashboard/processedvacationapplications', name: 'Processed vacation applications', component: Processedvacationapplications },
      { path: '/dashboard/addpost', name: 'Add post ', component: Addpost },
      { path: '/dashboard/notifications', name: 'Notifications', component: Notifications },
      { path: '/dashboard/scoi', name: 'Self Certification of Illness schemes', component: Scoi },
      { path: '/dashboard/advancedpaymentapplications', name: 'Payment in advance applications', component: Advancedpaymentapplications },
      { path: '/dashboard/processedadvancedpayment', name: 'Processed payment in advance applications', component: Processedadvancedpayment },
      { path: '/dashboard/processedscoi', name: 'Processed Self Certification of Illness', component: Processedscoi },
      { path: '/dashboard/adminschemas', name: 'Schemas', component: Adminschemas },
      { path: '/dashboard/contactschemas', name: 'Other schemas', component: Contactschemas },
      { path: '/dashboard/processedcontactschemas', name: 'Processed other schemas', component: Processedcontactschemas },
    ],
    meta: {
      requiresAuth: true
    }
    
  },
  {
    path: '/employee',
    name: 'Employee',
    component: Employee,
    children: [
      { path: '/employee/home', name: 'Home', component: Home },
      { path: '/employee/notifications', name: 'Employee notifications', component: Employeenotifications },
      { path: '/employee/schemas', name: 'Schemas', component: Schemas },
      { path: '/employee/applyforvacation', name: 'Apply for vacation', component: Applyforvacation },
      { path: '/employee/vacationapplications', name: 'Vacation applications', component: Vacationapplications },
      { path: '/employee/paymentinadvance', name: 'Apply for payment in advance', component: Paymentinadvance },
      { path: '/employee/paymentinadvanceapplications', name: 'Apply for payment in advance', component: Paymentinadvanceapplications },
      { path: '/employee/selfcertification', name: 'Self certification of illness', component: Selfcertification },
      { path: '/employee/other', name: 'Other', component: Contact },
      { path: '/employee/vaktplan', name: 'Vaktplan', component: Vaktplan },
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/forgotpassword',
    name: 'Reset password',
    component: Forgotpassword
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach(async (to, from, next) => {
//   const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
//   if(requiresAuth && !await firebase.getCurrentUser()) {
//     next('/')
//   } else {
//       next()
//   }
// })

// router.beforeEach(async (to, from, next) => {
//   const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
//   let currentUser = await firebase.auth().currentUser
//   if(requiresAuth && !currentUser) {
//     next('/')
//   } else {
//       next()
//   }
// })

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const currentUser = await firebase.auth().currentUser

  if(requiresAuth && !currentUser) next('/')
  else {
    next()
  }
})

export default router

