<template>
    <div>
        <h4>Vacation applications</h4>
        <p class="breadcrumb">
            <router-link to="adminschemas"> Schemas </router-link> / 
            <router-link to="employeevacationapplications"> Vacation applications </router-link>
        </p>

        <div style="margin-bottom:20px">
            <router-link to="processedvacationapplications">
                        Processed applications
            </router-link>
        </div>

        <p v-if="this.applications.length === 0">No new applications</p>
        <b-row>
            <b-col lg="12" v-for="application in applications" :key="application.docId" :data-id="application.docId" :data-userId="application.uid" class="cards tables"> 
                <p><span style="display: inline-block; width: 49%;"><b style="font-size: 12px;">Name:</b><br> {{ application.name }}</span><span style="display: inline-block; width: 49%;"><b style="font-size: 12px;">Company:</b><br> {{ application.company }}</span></p>
                <p><span style="display: inline-block; width: 100%;"><b style="font-size: 12px;">Date applied:</b><br> {{ application.dateApplied }}</span></p>
                <p><span style="display: inline-block; width: 49%;"><b style="font-size: 12px;">Vacation from:</b><br> {{ application.vacationFrom | reorderDate }}</span><span style="display: inline-block; width: 49%;"><b style="font-size: 12px;">Vacation to:</b><br> {{ application.vacationTo | reorderDate }}</span></p>
                <p><span style="width: 100%"><b style="font-size: 12px;">Comment from employee:</b><br> {{ application.additionalInformation }}</span></p>
                <hr>

                <textarea 
                    placeholder="Reason for decision (Optional)" 
                    class="reason-for-decision"
                    v-model="reasonForDecision"
                ></textarea>

                <div class="text-right">
                    <b-button type="submit" variant="success" @click="applicationDecision($event, 'Accepted')" class="aip-button">ACCEPT</b-button> <b-button type="submit" variant="danger" @click="applicationDecision($event, 'Declined')" class="aip-button">DECLINE</b-button>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import firebase from 'firebase/app'
    

    export default {
        data() {
            return {
                applications: [],
                company: "",
                firstName: "",
                lastName: "",
                uid: "",
                vacationFrom: "",
                vacationTo: "",
                reasonForDecision: "",
            }
        },

        methods: {
            async getVacationApplications() {
                const db = firebase.firestore()
                this.applications = [];
                const collection = await db.collection("vacationapplications").get()
                const documents = await collection.forEach(doc => {
                    if(doc.data().status == "Pending"){
                        this.applications.push(doc.data())
                    }
                })

                return await documents
            },

            async applicationDecision(e, status) {
                const db = firebase.firestore()
                const id = e.target.parentElement.parentElement.getAttribute("data-id")
                const userId = e.target.parentElement.parentElement.getAttribute("data-userid")

                try {
                    const updateVacationApplications = await db.collection("vacationapplications").doc(id).update({// eslint-disable-line
                    status: status,
                    reason: this.reasonForDecision,
                    signedBy: this.$store.state.userFullName
                    })

                    //REMEMBER TO UNCOMMENT
                    const updateUserVacationApplicationStatus = await db.collection("users").doc(userId).update({// eslint-disable-line
                        appliedForVacation: false
                    })

                    this.getVacationApplications();
                    console.log("Vacation application updated!")

                } catch (error) {
                    console.log("Something went wrong: ", error)
                }
            },
        },

        filters: {
            reorderDate(time){
                const year = time.slice(0,4);
                const month = time.slice(5,7)
                const day = time.slice(8,10)
                return `${day}/${month}/${year}`
            }
        },

        created() {
            this.getVacationApplications();

        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';

.reason-for-decision {
    width: 100%; 
    resize: none; 
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgb(192, 192, 192);
}
</style>