import { render, staticRenderFns } from "./Contactschemas.vue?vue&type=template&id=4f81d929&scoped=true&"
import script from "./Contactschemas.vue?vue&type=script&lang=js&"
export * from "./Contactschemas.vue?vue&type=script&lang=js&"
import style0 from "./Contactschemas.vue?vue&type=style&index=0&id=4f81d929&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f81d929",
  null
  
)

export default component.exports