<template>
    <div>
        <h4>Schemas</h4>
        <p class="breadcrumb">
            <router-link to="adminshchemas"> Schemas </router-link> / 
        </p>

        <router-link to="employeevacationapplications">Vacation applications</router-link><br>
        <router-link to="advancedpaymentapplications">Payment in advance applications</router-link><br>
        <router-link to="scoi">Self certification of illness</router-link>
        <router-link to="contactschemas">Other</router-link>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';
</style>