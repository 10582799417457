<template>
  <div id="app">
    <Navbar></Navbar>
    
    <b-container id="content">
        <router-view></router-view>
    </b-container>
  </div>
</template>

<script>
  import Navbar from '@/components/Navbar.vue'

  export default {
  name: 'App',
  components: {
    Navbar
  },

  created() {
      if (window.screen.width <= 768){
          this.$store.state.isMobile = true;
      } else {
          this.$store.state.isMobile = false;
      }
  }
}
</script>

<style lang="scss">
    @import 'scss/shared';

$aipyellow: #F1D149;
$aipblack: #1a1a1a;
$gobutton: #2191C2;

body {
  background-color: $jasmine !important;
  padding-bottom: 100px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#content {
  padding-top: 50px;
}

@media only screen and (max-width: 992px) {
        // nav {
        //   margin-bottom: 30px;
        // }
    }

</style>
