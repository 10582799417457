<template>
    <div>
        <h4>Schemas</h4>
        <p class="breadcrumb">
            <router-link to="adminschemas"> Schemas </router-link> / 
            <router-link to="scoi"> Other </router-link>
        </p>

        <div style="margin-bottom:20px">
            <router-link to="processedcontactschemas">
                        Processed schemas
            </router-link>
        </div>

        <b-form>
            <b-form-group id="input-group-3" label="Select schema:" label-for="input-3">
                <b-form-select
                id="input-3"
                :options="options"
                v-model="selectedSchema"
                v-on:change="changeSelectedSchema"
                required
                ></b-form-select>
            </b-form-group>
        </b-form>

        <p v-if="schemas.length === 0">No new schemas</p>
        
        <div>
            <b-row>
                <b-col lg="12" class="cards tables" v-for="scheme in schemas" :key="scheme.docId" :data-id="scheme.docId">
                    <!-- <h4 style="padding-left: 10px;" v-if="selectedSchema === 'all'">{{ scheme.typeOfForm }}</h4> -->
                    <p><span><b>Time received: </b><br>{{ scheme.datePosted.toString() }}</span></p>
                    <p><span><b>Name:</b><br>{{scheme.name}}</span> <span><b>Type:</b><br>{{ scheme.typeOfForm }}</span></p>

                    <!-- If type of form is other than resignation -->
                    <p v-if="scheme.typeOfForm != 'resignation'"><span><b>Details: </b><br>{{ scheme.details }}</span></p>

                    <!-- If type of form is resignation -->
                    <div v-if="scheme.typeOfForm === 'resignation'">

                        <p><span><b>Last working date: </b><br>{{ scheme.resignation.lastWorkingDate | reorderDate }}</span> <span><b>Delivered items:</b><br>{{ scheme.resignation.deliveredItems }}</span></p>
                        <p><span><b>Vacation money: </b><br>{{ scheme.resignation.vacationMoney }}</span></p>
                        <p><span><b>Comment from employee: </b><br>{{ scheme.resignation.details }}</span></p>
                    </div>

                    <hr>
                    <p style="margin-bottom: 0"><span><b>Add comment:</b><br></span></p>
                    <div style="padding: 0 10px">
                        <b-textarea v-model="commentFromLeader"></b-textarea>
                    
                        <div class="text-right" style="margin-top: 20px;">
                            <b-button variant="primary" type="submit" @click="updateSchema($event)" class="aip-button">Completed</b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>

    </div>
</template>

<script>
import firebase from 'firebase/app'
    export default {
        data() {
            return {
                userLoggedIn: '',
                schemas: [],
                selectedSchema: 'all',
                options: [
                    { text: 'All schemas', value: 'all'}, 
                    { text: 'Change of address, email, telephone etc', value: 'change'}, 
                    { text: 'Resignation', value: 'resignation'},
                    { text: 'Salary', value: 'salary'},
                    { text: 'Other cases', value: 'other'}
                ],
                commentFromLeader: null
            }
        },

        methods: {
            async getSchemas(){
                this.schemas = []
                const self = this;
                const db = firebase.firestore()
                const collection = await db.collection('other').get()
                const documents = await collection.forEach(doc => { // eslint-disable-line
                    if(!doc.data().treated){
                        if(self.selectedSchema === 'all') {
                        self.schemas.push(doc.data())
                        } else if(doc.data().typeOfForm === self.selectedSchema){
                            self.schemas.push(doc.data())
                        }
                    }
                })
                console.log(this.schemas);
            },

            async updateSchema(e) {
                const self = this
                const db = firebase.firestore()
                const docId = e.target.parentElement.parentElement.parentElement.getAttribute('data-id');// eslint-disable-line
                console.log(docId);
                const docRef = await db.collection('other').doc(docId)
                const updateDoc = await docRef.update({ // eslint-disable-line
                    commentFromLeader: self.commentFromLeader,
                    treated: true,
                    treatedBy: this.userLoggedIn
                })

                self.getSchemas()
                self.commentFromLeader = '';
            },

            changeSelectedSchema() {
                this.getSchemas();
            }
        },

        filters: {
            reorderDate(time){
                const year = time.slice(0,4);
                const month = time.slice(5,7)
                const day = time.slice(8,10)
                return `${day}/${month}/${year}`
            }
        },

        created() {
            const userInfo = JSON.parse(sessionStorage.getItem("lbUser"))
            this.userLoggedIn = userInfo.fullName;
            this.getSchemas()
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';
</style>