<template>
    <div>
        <h4>Payment in advance applications</h4>
        <p class="breadcrumb">
            <router-link to="adminschemas"> Schemas </router-link> / 
            <router-link to="advancedpaymentapplications"> Payment in advance applications </router-link>
        </p>

        <div style="margin-bottom: 20px;">
            <router-link to="processedadvancedpayment">
                        Processed applications
            </router-link>
        </div>

        <p v-if="applications.length === 0">No new applications</p>

        <div>
            <b-row>
                <b-col lg="12" class="cards tables" v-for="application in applications" :key="application.docId" :data-id="application.docId" :data-userId="application.uid">
                    <p><span><b>Date received: </b><br>{{ application.datePosted.toString() }}</span></p>
                    <p><span><b>Name:</b><br>{{application.name}}</span> <span v-if="application.otherAmount == ''"><b>Amount:</b><br>{{ application.amount }} NOK</span> <span v-if="application.otherAmount != ''"><b>Other amount:</b><br>{{ application.otherAmount }} NOK</span></p>
                    <p><span><b>Description:</b><br>{{application.description}}</span> <span><b>Account number:</b><br>{{application.accountNumber}}</span></p>
                    <!-- <p v-if="scheme.whoWasSick === 'Child sickness'"><span><b>Name of child: </b><br>{{ scheme.nameOfChild }}</span></p>
                    <p><span><b>From date:</b><br>{{ scheme.sickFromDate | reorderDate }}</span> <span><b>To date:</b><br>{{ scheme.sickToDate | reorderDate }}</span></p>
                    <p><span><b>Number of days: </b><br>{{ scheme.numberOfDays }} days</span></p>
                    <hr>-->
                    <hr>
                    <p style="margin-bottom: 0"><span><b>Comment:</b><br></span></p>
                    <div style="padding: 0 10px">
                        <b-textarea v-model="comment"></b-textarea>
                    
                        <div class="text-right" style="margin-top: 20px;">
                            <b-button variant="success" type="submit" @click="acceptDeclineApplication($event, 'Accepted')" class="aip-button" style="margin-right: 10px;">Accept</b-button>
                            <b-button variant="danger" type="submit" @click="acceptDeclineApplication($event, 'Declined')" class="aip-button">Decline</b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import firebase from 'firebase/app'
    export default {
        data() {
            return {
                userInfo: "",
                applications: [],
                comment: "",
                userLoggedIn: ""
            }
        },

        methods: {
            async getApplications(){
                this.applications = []
                const self = this;
                const db = firebase.firestore()
                const collection = await db.collection('paymentinadvance').get()
                
                const documents = await collection.forEach(doc => { // eslint-disable-line
                    if(doc.data().status == "Pending"){
                        self.applications.push(doc.data())
                        console.log(doc.data())
                    }
                })
            },

            async acceptDeclineApplication(e, status){// eslint-disable-line
                const self = this
                const db = firebase.firestore()
                const comment = this.comment
                const docId = e.target.parentElement.parentElement.parentElement.getAttribute('data-id');// eslint-disable-line
                const userId = e.target.parentElement.parentElement.parentElement.getAttribute("data-userid")
                const applicationStatus = status;
                let employeesNumberOfAcceptedApplication;
                console.log(docId, comment, status)
                const docRef = await db.collection('paymentinadvance').doc(docId)
                const updateDoc = await docRef.update({ // eslint-disable-line
                    status: status,
                    reason: comment,
                    signedBy: this.userLoggedIn
                })

                //Get number of times the employee has applied for and gotten approved application. 
                await db.collection("users").doc(userId).get()
                .then((doc) => {
                    console.log(doc.data())
                    // If the application is accepted, the employees number of times receiving advance payment increases.
                    applicationStatus == "Declined" ? employeesNumberOfAcceptedApplication = doc.data().advancePaymentTimes : employeesNumberOfAcceptedApplication = doc.data().advancePaymentTimes + 1;
                })

                //Updates employees status
                const updateUserVacationApplicationStatus = await db.collection("users").doc(userId).update({// eslint-disable-line
                    // appliedForVacation: false,
                    appliedForAdvance: false,
                    advancePaymentTimes: employeesNumberOfAcceptedApplication
                })

                self.getApplications()
                this.comment = "";
            },
        },

        created(){
            const userInfo = JSON.parse(sessionStorage.getItem("lbUser"))
            this.userLoggedIn = userInfo.fullName;
            this.getApplications()
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';
</style>