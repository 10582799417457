<template>
    <div class="component-content">
        <h4>Notifications</h4>

        <div id="mobile-messages-navigation" class="d-block d-md-none">
            <!-- <router-link to="/dashboard/addmessages">Inbox</router-link><br> -->
            <router-link to="/dashboard/addnotification">New notification</router-link><br>
            <router-link to="/dashboard/notifications">Manage notifications</router-link><br><br>
        </div>

        <p class="breadcrumb">Notifications / Manage notifications</p>

        <!-- IF NOTE ADDED -->
        <p v-if="noteDeleted"><b>Notification deleted</b></p>

        <b-overlay :show="loading" rounded="sm">
            <p v-if="this.messages.length === 0">No notifications found in database<p>
            <div v-for="message in messages" :key="message.id" :data-id="message.id" class="cards">
                <p style="font-size: 14px;">{{ message.timePosted }}</p>
                <h5>{{ message.title }}</h5>
                
                <hr>
                
                <p>{{ message.message }}</p>
                <p>{{ message.author }}</p>
                <div class="text-right">
                    <b-button type="submit" variant="danger" @click.prevent="deleteNotification($event)" class="aip-button">Delete</b-button>
                </div>
            </div>
        </b-overlay>
        
    </div>
</template>

<script>
    import firebase from 'firebase';

    export default {
        data() {
            return {
                messages: [],
                noteDeleted: false,
                loading: false,
            }
        },

        methods: {
            getNotifications() {
                const self = this;
                const db = firebase.firestore()
                this.loading = true
                db.collection("messages")
                    .orderBy("timestamp", "asc").onSnapshot(function(snapshot){
                        self.messages = [];
                        snapshot.forEach(function(snapshot){
                            self.messages.unshift(snapshot.data())
                        })
                        
                    }) 
                this.loading = false
            },

            deleteNotification(e) {
                const id = e.target.parentElement.parentElement.getAttribute("data-id");
                const db = firebase.firestore()
                const self = this
                this.loading = true
                this.noteDeleted = false
                db.collection("messages").where("id", "==", id).get()
                    .then(function(res) {
                        res.forEach(function(doc) {
                            doc.ref.delete();
                            self.noteDeleted = true
                            self.loading = false
                            console.log("Document deleted")
                        })
                    })
                    .catch(function(error) {
                        console.log("Error deleting document: ", error)
                    })
            }
        },

        created() {
            if(this.$store.state.userIs != "admin") {
                this.$router.push("/dashboard/adminhome")
            }
            this.getNotifications()
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';



</style>





