<template>
    <b-row>

        <b-col lg="8" offset-lg="2">
            <p v-if="reqMessage != ''" class="infoMessage">{{ reqMessage }}</p>
            <b-form v-on:submit.prevent="login">
                <b-form-group
                    id="input-group-1"
                    label="Email:"
                    label-for="emailAddress"
                >
                    <b-form-input
                        id="emailAddress"
                        type="email"
                        placeholder="Enter your email"
                        v-model="email"
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="input-group-2"
                    label="Password:"
                    label-for="password"
                >
                    <b-form-input
                        id="password"
                        type="password"
                        placeholder="Enter your password"
                        v-model="password"
                        required
                    ></b-form-input>
                </b-form-group>
                
                <div class="text-center">
                    
                    <b-button  type="submit" variant="primary" id="login-button" class="aip-button">Login</b-button>
                    <p style="font-size: 14px; margin-top: 20px;">Forgot your password? Click <router-link to="/forgotpassword">here</router-link>.</p>
                </div>
            </b-form>
        </b-col>
    </b-row>
</template>

<script>
    import firebase from 'firebase/app'
    import 'firebase/auth'

    export default {
        data() {
            return {
                email: "",
                password: "",
                reqMessage: ""
            }
        },

        methods: {
            async login(){
                const auth = firebase.auth()
                const dbRef = firebase.firestore().collection("users")
                try {
                    const loginWithEmail = await auth.signInWithEmailAndPassword(this.email, this.password)// eslint-disable-line
                    const userToken = await auth.currentUser.getIdTokenResult() 
                    const userDoc = await dbRef.get()
                    const userId = await userToken.claims.user_id
                    sessionStorage.setItem("lbUserTk", JSON.stringify(userToken));

                    for(const doc of userDoc.docs){
                        if(userId === doc.id){
                            sessionStorage.setItem("lbUser", JSON.stringify(doc.data()));
                            sessionStorage.setItem("lbUserTk", JSON.stringify(userToken));
                        }
                    }

                    console.log(userDoc)

                    if(userToken.claims.admin) {
                        this.$router.push('/dashboard/adminhome')
                    } else if(userToken.claims.teamleader) {
                        this.$router.push('/dashboard/adminhome')
                    } else {
                        this.$router.push('/employee/home')
                    }
                }
                catch(error) {
                    if(error.code == "auth/user-not-found"){
                        this.reqMessage = "User not found"
                    } else if(error.code =="auth/wrong-password") {
                        this.reqMessage = "Wrong password"
                    } else {
                        this.reqMessage = "Something went wrong. Please try again. If the problem continues, contact support@podokladka.no." + error
                    }
                }
            }
        },

        created(){
            console.log("Hurraasd")
        }
    }
</script>

<style lang="scss" scoped>
$card-color: #ffffff;
@import '../scss/shared';

.infoMessage {
    color: red;
    border: 1px solid red;
    text-align: center;
}
</style>