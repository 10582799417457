<template>
    <div>
        <h4>Users</h4>
        <!-- MOBILE VIEW -->
        <div id="mobile-messages-navigation" class="d-block d-md-none">
            <!-- <router-link to="/dashboard/addmessages">Inbox</router-link><br> -->
            <router-link to="/dashboard/users">Add user</router-link><br>
            <router-link to="/dashboard/manageusers">Manage users</router-link><br><br>
        </div>

        <!-- BREADCRUMB -->
        <p class="breadcrumb">Users / Manage users</p>

        <p v-if="this.users.length === 0">No users found in database</p>

       
        
        <!-- SEARCH BAR -->
        <div class="employee-search" v-if="this.users.length != 0">
            <b-icon icon="search" class="employee-search-icon"></b-icon>
            <b-input
            id="employee-search-input"
            placeholder="Search users"
            v-model="employeeName"
            @input="filterUsers"
            ></b-input>
        </div>

        <!-- COMPANY FILTER -->
        <!-- <div class="company-search" v-if="this.users.length != 0">

            <b-form @change="filterUsers">
                <b-form-group id="select-company-form-group" label-for="select-company">
                    <b-icon icon="list" class="company-search-icon"></b-icon>
                    <b-form-select
                    id="select-company"
                    v-model="selectedCompany"
                    :options="[{ value: '', text: 'Filter by company' }, 'ALTiPROSJEKT AS', 'AiP BETONGSAGING AS', 'AiP ENTREPENØR AS', 'AiP SANERING AS', 'AiP UTLEIE AS']"
                    >
                    </b-form-select>
                </b-form-group>
            </b-form>
        </div> -->

        <b-overlay :show="loading" rounded="sm">
            <!-- IF USER DELETED -->
            <p v-if="reqMessage != ''" class="infoMessage" v-bind:class="[succErr === 'success' ? 'success' : succErr === 'error' ? 'error' : '' ]">{{ reqMessage }}</p>
            <p v-if="this.employeeName && this.filteredUsers.length === 0">No user found</p>

            <div lg="9" v-for="user in filteredUsers" :key="user.id" :data-id="user.id" class="user-card cards tables">
                <p><span><b>Name:</b><br> {{ user.fullName }}</span> <span><b>Company:</b><br> {{ user.company }}</span></p><br>
                <p><span><b>Email:</b><br> {{ user.email }}</span></p>
                <hr>
                <div class="text-right">
                    <b-button type="submit" variant="danger" @click="deleteUser($event)" class="aip-button">Delete user</b-button>
                </div>
            </div>
        </b-overlay>
    </div>
</template>

<script>
    import firebase from 'firebase/app'

    export default {
        data() {
            return {
                users: [],
                filteredUsers: [],
                employeeName: "",
                selectedCompany: "",
                loading: false,
                succErr: "",
                reqMessage: ""
            }
        },

        methods: {
            getUsers(){
                const self = this;
                this.loading = true
                firebase.firestore().collection("users")
                    .orderBy("fullName", "asc")
                    .onSnapshot(function(snapshot) {
                        self.users = [];
                        snapshot.forEach(function(doc){
                            self.users.push(doc.data())
                        })
                        self.filteredUsers = self.users
                        self.loading = false
                    })
            },

            deleteUser(e) {
                const uid = e.target.parentElement.parentElement.getAttribute("data-id")
                const delUsr = firebase.functions().httpsCallable('deleteUser');
                this.loading = true
                this.userDeleted = false
                delUsr(uid).then((res) => { // eslint-disable-line
                    firebase.firestore().collection("users").doc(uid).delete()
                        .then(() => {
                            this.loading = false
                            this.succErr = "success";
                            this.reqMessage = "User deleted"
                        })
                        .catch((error) => {
                            this.succErr = "error";
                            this.reqMessage = "Something went wrong."
                            console.log("Error removing user: ", error)
                        })
                })
            },

            filterUsers() {
                const self = this
                if(this.employeeName != "" && !this.selectedCompany) {
                    this.filteredUsers = this.users.filter(function(user) {
                        return user.fullName.toLowerCase().includes(self.employeeName.toLowerCase())
                    })
                } else if(this.selectedCompany && this.employeeName != "") {
                    let selectedCompany = this.selectedCompany.toLowerCase()
                    this.filteredUsers = this.users.filter(function(user) {
                        return user.fullName.toLowerCase().includes(self.employeeName.toLowerCase()) && user.company.toLowerCase() == selectedCompany
                    })
                } else if(this.selectedCompany && this.employeeName == "") {
                    let selectedCompany = this.selectedCompany.toLowerCase()
                    this.filteredUsers = this.users.filter(function(user){
                        return user.company.toLowerCase() == selectedCompany
                    })
                } else {
                    this.filteredUsers = this.users
                }
            },
        },

        beforeCreate() {
            const userToken = JSON.parse(sessionStorage.getItem("lbUserTk"))

            if(!userToken.claims.admin) {
                this.$router.push("/dashboard/adminhome")
            }
        },
        
        created() {
            this.getUsers()
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';

.employee-search {
    position: relative;
    .employee-search-icon {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    #employee-search-input {
        margin-bottom: 20px;
        padding-left: 35px;
    }

    #employee-searc-input::placeholder {
        margin-left: 20px;
    }

}

.company-search {
    margin-bottom: 20px;

    #select-company-form-group {
        position: relative;

        .company-search-icon {
            position: absolute;
            top: 12px;
            left: 10px
        }

        select {
            padding-left: 35px;
            color: rgb(110, 110, 110);
        }
    }
}


.user-card { 
        margin-bottom: 20px;

        p {
            padding: 0;
            margin: 0;
        }
    }
</style>