<template>
    <div>
        <h4>Vacation applications</h4>
        <p class="breadcrumb">
            <router-link to="adminschemas"> Schemas </router-link> / 
            <router-link to="employeevacationapplications"> Vacation applications </router-link> /
            <router-link to="processedvacationapplications"> Processed vacation applications </router-link>
        </p>

        <div style="margin-bottom: 20px;">
            <router-link to="employeevacationapplications">
                        
                        Pending applications

            </router-link>
        </div>

        <div class="employee-search" v-if="this.applications.length != 0">
            <b-icon icon="search" class="employee-search-icon"></b-icon>
            <b-input
            id="employee-search-input"
            placeholder="Filter by name"
            v-model="name"
            @input="filterApplications"
            ></b-input>
        </div>

        <b-overlay :show="loading" rounded="sm">
            <p v-if="this.filteredApplications.length === 0">No applications</p>
            <b-row>
                <b-col 
                    lg="12" 
                    v-for="application in filteredApplications" 
                    :key="application.docId" 
                    :data-id="application.docId" 
                    :data-userId="application.uid" 
                    class="cards tables"
                    v-bind:class="[application.status === 'Accepted' ? 'accepted' : 
                                   application.status === 'Declined' ? 'rejected' : '']"> 
                        <h5>{{ application.status }}</h5>
                        <hr>
                        <p><span style="display: inline-block; width: 49%"><b>Name: </b><br>{{ application.name }}</span> <span style="display: inline-block; width: 49%"><b>Company:</b><br>{{ application.company }}</span></p>
                        <p><span style="display: inline-block; width: 49%"><b>Date applied: </b><br>{{ application.dateApplied }}</span></p>
                        <p><span style="display: inline-block; width: 49%"><b>Vacation from: </b><br>{{ application.vacationFrom | reorderDate }}</span> <span style="display: inline-block; width: 49%"><b>Vacation to:</b><br> {{ application.vacationTo | reorderDate }}</span></p>
                        <p><span style="display: inline-block; width: 49%" v-if="application.additionalInformation != ''"><b>Comment from employee: </b><br>{{ application.additionalInformation }}</span> <span style="display: inline-block; width: 49%" v-if="application.reason"><b>Comment from employeer:</b><br> {{ application.reason }}</span></p>
                        <p><span style="display: inline-block; width: 100%" ><b>Signed by: </b><br>{{ application.signedBy }}</span></p>
                        
                </b-col>
            </b-row>
        </b-overlay>
    </div>
</template>

<script>
    import firebase from 'firebase/app'
    import moment from 'moment'

    export default {

        data() {
            return {
                loading: false,
                applications: [],
                filteredApplications: [],
                name: "",
            }
        },

        methods: {
            async getApplications(){
                const db = firebase.firestore()
                this.loading = true
                
                const collection = await db.collection("vacationapplications").orderBy("timestamp", "desc").get()
                const docs = await collection.forEach(doc => {
                    if(doc.data().status == "Accepted" || doc.data().status == "Declined") {
                        this.applications.push(doc.data())
                    }
                })

                this.loading = false

                return docs
            },

            filterApplications() {
                console.log(this.name)
                const self = this;
                this.filteredApplications = this.applications.filter(function(application){
                    return application.name.toLowerCase().includes(self.name.toLowerCase())
                })
            }
        },

        filters: {
            parseTime(time){
                moment(time).format('Do/MM/YYYY, HH:mm')
            },

            reorderDate(time){
                const year = time.slice(0,4);
                const month = time.slice(5,7)
                const day = time.slice(8,10)
                return `${day}/${month}/${year}`
            }
        },

        created() {
            this.getApplications()
            console.log(this.applications)
            this.filteredApplications = this.applications
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';
.employee-search {
    position: relative;
    .employee-search-icon {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    #employee-search-input {
        margin-bottom: 20px;
        padding-left: 35px;
    }

    #employee-searc-input::placeholder {
        margin-left: 20px;
    }

}
</style>