<template>
    <div>
        <h4>Vaktplan</h4>
        <p class="breadcrumb"><router-link to="home">General</router-link> / <router-link to="vaktplan">Vaktplan</router-link></p>
        
        <h5>Current vaktplan</h5>
        <a target="_blank" href="https://torweb.net/elwi/vaktplan/vaktplan.pdf">Vaktplan 21 - 25 February 2022</a><br><br>

        <h6>Previous</h6>
        <a href="#">Vaktplan 14 - 18 February 2022</a><br>
        <a href="#">Vaktplan 7 - 11 February 2022</a><br>
        <a href="#">Vaktplan 31 March - 25 February 2022</a><br>
        <a href="#">Vaktplan 24 - 28 March 2022</a><br>
        <a href="#">Vaktplan 17 - 21 March 2022</a><br>
        <a href="#">Vaktplan 10 - 14 March 2022</a><br>
        <a href="#">Vaktplan 3 - 7 March 2022</a><br>

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';
</style>