<template>
    <div>
        <h4>Self-certification of illness</h4>
        <p class="breadcrumb"><router-link to="schemas"> Schemas </router-link> / <router-link to="applyforvacation"> Self-certification for illness </router-link></p>

        <p class="infoMessage accepted" v-if="formSent">Form sent</p>

        <div class="cards" v-if="this.loaded">
            <!-- <p><b>Absence from Work must be reported to the employer first day of absence before 07.00</b></p>
            <p><b>This form must be delivered to the employer the first working day after the absence.</b></p> -->
            
            <div class="tables">
                <b-form v-on:submit.prevent="selfCertificationIllness($event)">
                    <b-form-group label="Choose the correct option:" v-slot="{ ariaDescribedby }" style="font-size: 12px; font-weight: 600">
                        <b-form-radio-group
                            id="checkbox-group-1"
                            v-model="typeOfScoi"
                            :aria-describedby="ariaDescribedby"
                            name="type-of-scoi"
                        >
                            <b-form-radio value="Egenmelding"  style="font-size: 16px; font-weight: 400;">Egenmelding</b-form-radio>
                            <b-form-radio value="Sykemelding" style="font-size: 16px; font-weight: 400;">Sykemelding</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>

                    <!-- IF TYPE OF SICKNESS IS CHILD SICKNESS -->
                    <b-form-group label="Type of sickness:" v-slot="{ ariaDescribedby }" style="font-size: 12px; font-weight: 600" v-if="typeOfScoi === 'Egenmelding'">
                        <b-form-radio-group
                            id="checkbox-group-2"
                            v-model="whoWasSick"
                            :aria-describedby="ariaDescribedby"
                            name="who-was-sick"
                        >
                            <b-form-radio value="Own sickess"  style="font-size: 16px; font-weight: 400;">Own sickness</b-form-radio>
                            <b-form-radio value="Child sickness" style="font-size: 16px; font-weight: 400;">Child sickness</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>

                    <!-- IF TYPE OF SCOI IS SYKEMELDING -->
                     <b-form-group label="Choose correct option:" v-slot="{ ariaDescribedby }" style="font-size: 12px; font-weight: 600" v-if="typeOfScoi === 'Sykemelding'">
                        <b-form-radio-group
                            id="checkbox-group-2"
                            v-model="typeOfSykemelding"
                            :aria-describedby="ariaDescribedby"
                            name="who-was-sick"
                        >
                            <b-form-radio value="Altinn"  style="font-size: 16px; font-weight: 400;">Altinn</b-form-radio>
                            <b-form-radio value="Paperversion" style="font-size: 16px; font-weight: 400;">Paperversion</b-form-radio>
                            <b-form-radio value="Sykemelding abroad" style="font-size: 16px; font-weight: 400;">Sykemelding abroad</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>

                    <!-- IF TYPE OF SYKEMELDING IS ABROAD  -->
                    <div v-if="typeOfSykemelding === 'Sykemelding abroad'" style="font-weight: bold; color: red;">
                        <p>Remember to fill out self-declaration, and scan and deliver it to elwira@aipgruppen.no</p>
                        <p>Pamiętaj o wypełnieniu deklaracji własnej, zeskanowaniu i dostarczeniu do elwira@aipgruppen.no</p>
                        <p>Nepamirškite užpildyti savęs deklaracijos, nuskaityti ir pristatyti elwira@aipgruppen.no</p>
                    </div>
                    

                    <b-form-group
                        id="input-group-2"
                        label="Name of child: "
                        label-for="nameOfChild"
                        style="font-size: 12px; font-weight: 600"
                        v-if="whoWasSick == 'Child sickness'"
                    >
                        <b-form-input
                            id="nameOfChild"
                            type="text"
                            placeholder="Name of child"
                            v-model="nameOfChild"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-group-2"
                        label="Childs date of birth: "
                        label-for="dateOfBirthChild"
                        style="font-size: 12px; font-weight: 600"
                        v-if="whoWasSick == 'Child sickness'"
                    >
                        <b-form-input
                            id="dateOfBirthChild"
                            type="date"
                            placeholder="Name of child"
                            v-model="dateOfBirthChild"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <p class="infoMessage rejected" v-if="wrongNumberOfDays">Please select valid dates.</p>
                    <b-form-group
                        id="input-group-4"
                        label="Date: "
                        label-for="fromDate"
                        style="display: inline-block; font-size: 12px; font-weight: 600; width: 49%"
                    >
                        <b-form-input
                            id="fromDate"
                            type="date"
                            placeholder=""
                            v-model="fromDate"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        v-if="typeOfScoi === 'Sykemelding'"
                        id="input-group-5"
                        label="To date :"
                        label-for="toDate"
                        style="display: inline-block;font-size: 12px; font-weight: 600; width: 49%; padding-left: 20px;"
                    >
                        <b-form-input
                            id="toDate"
                            type="date"
                            placeholder=""
                            v-model="toDate"
                        ></b-form-input>
                    </b-form-group>

                    <div>
                        <p><span style="padding: 0;"><b>Date:</b><br>{{ date }}</span><span style="padding-left: 20px ;"><b>Name:</b><br>{{ this.userInfo.fullName }}</span></p>    
                    </div>                    
                    
                    <div class="text-right">
                        <b-button type="submit" variant="primary" id="add-employee-btn" class="aip-button">Send form</b-button>
                    </div>

                    
                </b-form>
            </div>
        </div>

    </div>
</template>

<script>
import firebase from 'firebase/app'// eslint-disable-line
import moment from 'moment'
    export default {
        
        data() {
            return {
                loaded: false,
                userInfo: null,
                date: "",
                typeOfScoi: "",
                typeOfSykemelding: "",
                whoWasSick: "",
                nameOfChild: "",
                dateOfBirthChild: "",
                fromDate: "",
                toDate: "",
                formSent: false,
                wrongNumberOfDays: false,
            }
        },

        methods: {
            countNumberOfDays(){
                const from = this.fromDate.slice(8,10)
                const to = this.toDate.slice(8,10)
                return to - from;
            },
            async selfCertificationIllness(e){
                this.countNumberOfDays()
                const db = firebase.firestore();
                const newDoc = db.collection("selfcertificationofillness").doc()
                const newDocRef = await newDoc.get()
                const userId = this.userInfo.id;

                try {
                    if(this.typeOfScoi === 'Sykemelding'){
                        if(this.countNumberOfDays() === 0 || this.countNumberOfDays() < 0){
                            this.wrongNumberOfDays = true
                            return
                        } else {
                            this.numberOfDays = this.countNumberOfDays()
                        }
                    } else {
                        this.numberOfDays = 1;
                    }
                    
                    db.collection("selfcertificationofillness").doc(newDocRef.id).set({
                        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                        docId: newDocRef.id,
                        uid: userId,
                        scoiStatus: "Pending",
                        datePosted: this.date,
                        name: this.userInfo.fullName,
                        typeOfScoi: this.typeOfScoi,
                        typeOfSykemelding: this.typeOfSykemelding,
                        whoWasSick: this.whoWasSick,
                        nameOfChild: this.nameOfChild,
                        dateOfBirthChild: this.dateOfBirthChild,
                        sickFromDate: this.fromDate,
                        sickToDate: this.toDate,
                        numberOfDays: this.numberOfDays,
                    })

                    this.formSent = true
                    this.wrongNumberOfDays = false
                    e.target.reset()
                    console.log("Sucess")
                } catch {
                    console.log("Error")
                }
            }
        },

        filters: {
            parseTime(time){
                moment(time).format('Do/MM/YYYY, HH:mm')
            }
        },

        beforeCreate() {
            // TODO Change all this.$store to session storage!!
            const getUserInfo = async () => JSON.parse(sessionStorage.getItem("lbUser"));
            getUserInfo().then(res => {
                this.userInfo = res
                this.loaded = true
                console.log(this.userInfo, 123123123)
            })

        },

        created() {
            const d = new Date();
            const parsedD = moment(d).format('Do/MM/YYYY, h:mm a')
            this.date = parsedD;
        }
        
    }
</script>

<style lang="scss" scoped>
@import '../../scss/shared';
</style>