<template>
    <div id="navbar">
        <b-navbar toggleable="lg" type="dark" variant="primary" id="navbar-inner">
            <b-container>
            <b-navbar-brand href="#" id="logo"><img src="../assets/aiplogo.png" alt="AltIProsjekt" id="logopng"></b-navbar-brand>
            </b-container>
            <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->

            <!-- <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                    <router-link to="/coming">Apply for job</router-link>
                </b-navbar-nav>
            </b-collapse> -->
        </b-navbar>
    </div>
</template>

<script>
    export default {
        name: 'Navbar',
        props: {

        }
    }
</script>

<style lang="scss" scoped>

#navbar {

    #navbar-inner {
        background-color: black !important;
    }
    box-shadow: 0px 1px 5px rgb(134, 134, 134);
    a {
        font-weight: bold;
        color: #ffffff;
        padding-left: 20px;

        &.router-link-exact-active {
            color: #ebff33;
        }
    }

    #logo {
        padding: 10px 0;
        
        #logopng {
            width: 150px;
        }
    }
}


</style>